/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import background1 from './images/background1.svg';
import background2 from './images/background2.svg';
import style from './about.module.scss';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('howItWorks.about')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.whatIfUPoS'),
                        }}
                    />
                    <div className={style.about__content}>
                        <p
                            className={style.about__content_text}
                            dangerouslySetInnerHTML={{
                                __html: t('texts.ultimatumPosText1'),
                            }}
                        />
                        <div className={style.about__contentBg}>
                            <p className={style.about__content_text}>
                                {t('texts.ultimatumPosText2')}
                            </p>

                            <p className={style.about__content_text}>
                                {t('texts.ultimatumPosText3')}
                            </p>
                            <img
                                src={background1}
                                alt="background"
                                className={style.about__background}
                            />
                        </div>
                        <div className={style.about__contentBg}>
                            <h3 className={style.about__content_title}>
                                {t('texts.ultimatumPosText4')}
                            </h3>
                            <p className={style.about__content_text}>
                                {t('texts.ultimatumPosText5')}
                            </p>
                            <img
                                src={background2}
                                alt="background"
                                className={style.about__background}
                            />
                        </div>
                        <div className={style.about__contentBg}>
                            <h3 className={style.about__content_title}>
                                {t('texts.ultimatumPosText6')}
                            </h3>
                            <p className={style.about__content_text}>
                                {t('texts.ultimatumPosText7')}
                            </p>
                            <p className={style.about__content_text}>
                                {t('texts.ultimatumPosText8')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
