/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import background from './images/background.svg';
import style from './how-it-work.module.scss';

const HowItWork = () => {
    const { t } = useTranslation();

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('aboutUs.howItWorksText')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.leasingDeligated'),
                        }}
                    />
                    <div className={style.about__content}>
                        <p className={style.about__content_text}>
                            {t('texts.deligatedStaking1')}
                        </p>
                        <p className={style.about__content_text}>
                            {t('texts.deligatedStaking2')}
                        </p>
                    </div>

                    <img
                        src={background}
                        alt="background"
                        className={style.about__background}
                    />
                </div>
            </div>
        </div>
    );
};

export default HowItWork;
