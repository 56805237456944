import React from 'react';
import { useTranslation } from 'react-i18next';
import notification from '../../../../helpers/notifications';
import copyToClipboard from '../../../../helpers/copy-to-clipboard';
import { EXPLORER_TESTNET } from '../../../../constants';
import copyIcon from '../../../assets/images/icons/copy-icon.svg';
import style from './explorer.module.scss';

const Explorer = () => {
    const { t } = useTranslation();

    const copied = address => {
        copyToClipboard(address);
        notification(t('successNotification'), t('сopiedToClipboard'), 'success');
    };

    return (
        <div className={style.explorer}>
            <h2 className={style.explorer__title}>{t('navigation.tryIt')}</h2>
            <div className={style.explorer__rightSide}>
                <p className={style.explorer__rightSide_title}>
                    Orion <span>Explorer</span>
                </p>
                <div className={style.explorer__bottom}>
                    <a
                        className={style.explorer__bottom_link}
                        href={EXPLORER_TESTNET}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {EXPLORER_TESTNET}
                    </a>
                    <div onClick={() => copied(EXPLORER_TESTNET)}>
                        <img
                            className={style.explorer__bottom_copy}
                            src={copyIcon}
                            alt="copyIcon"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Explorer;
