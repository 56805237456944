/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './about.module.scss';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('howItWorks.about')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.orionNotBeta'),
                        }}
                    />
                    <div className={style.about__content}>
                        <p className={style.about__content_text}>
                            {t('texts.orionText1')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
