/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './for-exchanges-users.module.scss';

const ForExchangesUsers = () => {
    const { t } = useTranslation();

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('howItWorks.about')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.forExchangesUsers'),
                        }}
                    />
                    <div className={style.about__content}>
                        <p className={style.about__content_text}>
                            {t('texts.forExchangesUsersText1')}
                        </p>
                        <p className={style.about__content_text}>
                            {t('texts.forExchangesUsersText2')}
                        </p>
                        <p className={style.about__content_text}>
                            {t('texts.forExchangesUsersText3')}
                            <a href="mailto:airdrop@btcu.io"> airdrop@btcu.io</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForExchangesUsers;
