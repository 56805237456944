/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withPostService from '../../../hoc/with-post-service';
import fetchSubscribeNewsAction from '../../../../actions/post-subscribe-news.actions';
import { compose } from '../../../../utils';
import handsIcon from '../../../assets/images/icons/hands-icon.svg';
import { emailValid } from '../../../../helpers';
import style from './subscribe.module.scss';

class Subscribe extends Component {
    static defaultProps = {
        t: () => {},
        submitSubscribe: () => {},
        success: false,
    };

    static propTypes = {
        t: PropTypes.func,
        submitSubscribe: PropTypes.func,
        success: PropTypes.bool,
    };

    state = {
        email: '',
        isEmailError: false,
    };

    componentDidUpdate(prevProps) {
        const { success } = this.props;

        if (success && success !== prevProps.success) {
            this.setState({
                email: '',
                isEmailError: false,
            });
        }
    }

    inputOnChange = async event => {
        const { name, value } = event.target;
        await this.emailValidation(name, value);
    };

    emailValidation = (name, value) => {
        const { t } = this.props;

        this.setState({
            [name]: value.toLowerCase().trim(),
            isEmailError: emailValid(value) ? t('error.wrong_email') : '',
        });
    };

    send = event => {
        event.preventDefault();
        const { t, submitSubscribe } = this.props;
        const { email } = this.state;

        const data = {
            email,
            type: 'COMMUNITY',
            isVerified: '',
        };

        submitSubscribe(data, t);
    };

    render() {
        const { email, isEmailError } = this.state;
        const { t } = this.props;

        const btnStyle = {
            opacity: '0.5',
            userSelect: 'none',
            pointerEvents: 'none',
        };

        const isDiasbleBtn = isEmailError || !email.length;

        const inputStyle = isEmailError
            ? classNames(
                style.subscribe__bottom_input,
                style.subscribe__bottom_inputError,
            )
            : style.subscribe__bottom_input;

        return (
            <div className={style.subscribe}>
                <h2 className={style.subscribe__title}>{t('headings.subscribe')}</h2>
                <form className={style.subscribe__rightSide} onSubmit={this.send}>
                    <p
                        className={style.subscribe__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.btcuNews'),
                        }}
                    />
                    <p className={style.subscribe__rightSide_subTitle}>
                        {t('texts.dontMissTechnicalUpdates')}
                    </p>
                    <div className={style.subscribe__bottom}>
                        <div className={style.subscribe__bottom_wrapper}>
                            <img src={handsIcon} alt="icon" />
                            <p className={style.subscribe__bottom_text}>
                                {t('texts.subscribeAllNews')}
                            </p>
                        </div>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.inputOnChange}
                            placeholder={t('forms.enterYourEmail')}
                            className={inputStyle}
                        />
                        <button
                            style={isDiasbleBtn ? btnStyle : {}}
                            type="submit"
                            className={style.subscribe__bottom_button}
                            disabled={!!isEmailError}
                        >
                            {t('buttons.send')}
                        </button>
                    </div>
                </form>
            </div>

        );
    }
}

const mapStateToProps = state => {
    const {
        subscribeNews: { success },
    } = state;

    return {
        success,
    };
};

const mapDispatchToProps = (dispatch, { postService }) => bindActionCreators(
    {
        submitSubscribe: fetchSubscribeNewsAction(postService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
)(Subscribe);
