import React from 'react';
import PropTypes from 'prop-types';

const ArrowRightIcon = ({ className }) => (
    <svg
        className={className}
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.69623 5.26759C2.61576 5.34805 2.57056 5.45718 2.57056 5.57096C2.57056 5.68475 2.61576 5.79388 2.69623 5.87434C2.77669 5.9548 2.88582 6 2.99962 6C3.11341 6 3.22254 5.9548 3.30301 5.87434L5.87411 3.30337C5.91401 3.26357 5.94567 3.21629 5.96728 3.16423C5.98888 3.11217 6 3.05636 6 3C6 2.94364 5.98888 2.88783 5.96728 2.83577C5.94567 2.78371 5.91401 2.73643 5.87411 2.69663L3.30301 0.125662C3.26317 0.085822 3.21587 0.0542192 3.16381 0.0326582C3.11175 0.0110971 3.05596 4.19779e-10 2.99962 0C2.94327 -4.19778e-10 2.88748 0.0110971 2.83542 0.0326582C2.78337 0.0542192 2.73607 0.085822 2.69623 0.125662C2.65639 0.165501 2.62478 0.212798 2.60322 0.264851C2.58166 0.316904 2.57056 0.372694 2.57056 0.429035C2.57056 0.485377 2.58166 0.541167 2.60322 0.59322C2.62478 0.645273 2.65639 0.69257 2.69623 0.732409L4.53628 2.57151H0.428517C0.314867 2.57151 0.205872 2.61665 0.12551 2.69701C0.0451472 2.77737 0 2.88636 0 3C0 3.11364 0.0451472 3.22263 0.12551 3.30299C0.205872 3.38335 0.314867 3.42849 0.428517 3.42849H4.53628L2.69623 5.26759Z"
            fill="white"
        />
    </svg>
);

ArrowRightIcon.defaultProps = {
    className: '',
};

ArrowRightIcon.propTypes = {
    className: PropTypes.string,
};

export default ArrowRightIcon;
