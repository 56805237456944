/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    smartContractsPath,
    ultimatumPosPath,
    leasingAndStakingPath,
    privateTransactionsPath,
} from '../../../../constants';
import contractsIcon from './images/smart-contracts.svg';
import ultimatumIcon from './images/ultimatum-pos.svg';
import leasingIcon from './images/leasing.svg';
import transactionsIcon from './images/transactions.svg';
import style from './about.module.scss';

const About = () => {
    const { t } = useTranslation();

    const data = [
        {
            img: ultimatumIcon,
            title: 'Ultimatum <br/> PoS',
            link: ultimatumPosPath,
            btnText: t('buttons.seeMore'),
        },
        {
            img: contractsIcon,
            title: t('howItWorks.smartContracts'),
            link: smartContractsPath,
            btnText: t('buttons.seeMore'),
        },
        {
            img: leasingIcon,
            title: t('howItWorks.leasingStaking'),
            link: leasingAndStakingPath,
            btnText: t('buttons.seeMore'),
        },
        {
            img: transactionsIcon,
            title: t('howItWorks.privateTransactions'),
            link: privateTransactionsPath,
            btnText: t('buttons.seeMore'),
        },
    ];

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('howItWorks.about')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{ __html: t('aboutUs.howItWorks') }}
                    />
                    <div className={style.about__content}>
                        <p
                            className={style.about__content_text}
                            dangerouslySetInnerHTML={{
                                __html: t('howItWorks.description'),
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={style.container}>
                {data.map(item => {
                    const {
                        img, title, link, btnText,
                    } = item;

                    return (
                        <div key={img} className={style.container__item}>
                            <img
                                className={style.container__item_img}
                                src={img}
                                alt="img"
                            />
                            <div className={style.container__item_bottom}>
                                <p
                                    className={style.container__item_title}
                                    dangerouslySetInnerHTML={{
                                        __html: title,
                                    }}
                                />
                                <Link to={link} className={style.container__item_button}>
                                    {btnText}
                                </Link>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default About;
