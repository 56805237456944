import React, { Fragment } from 'react';
import FirstScreen from './first-screen';
import About from './about';
import Ambassadors from './ambassadors';
import Team from './team';

const WhoWeAre = () => (
    <Fragment>
        <FirstScreen />
        <About />
        <Team />
        <Ambassadors />
    </Fragment>
);

export default WhoWeAre;
