import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Menu, Icon, Layout } from 'antd';
import { compose } from '../../../../utils';
import { developersDocsPath } from '../../../../constants/path-location';
import style from './aside.module.scss';
import './aside.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;
class Aside extends Component {
    state = {
        collapsed: false,
    };

    componentDidMount() {
        const isCollapsed = localStorage.getItem('aside_collapsed_btcu');

        this.setState({
            collapsed: isCollapsed === 'true',
        });
    }

    toggleCollapsed = () => {
        this.setState(state => ({
            collapsed: !state.collapsed,
        }), () => {
            const { collapsed } = this.state;
            localStorage.setItem('aside_collapsed_btcu', collapsed);
        });
    };


    onMenuClick = path => {
        const { history } = this.props;
        history.push(path.key);
    }

    render() {
        const { t } = this.props;
        const { collapsed } = this.state;
        console.log(t);

        return (
            <div className={style.aside} id="aside">
                <Sider
                    collapsible
                    trigger={null}
                    collapsed={collapsed}
                    onCollapse={this.toggleCollapsed}
                >
                    <Menu
                        defaultSelectedKeys={[developersDocsPath]}
                        defaultOpenKeys={['sub1', 'sub2']}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={collapsed}
                        onClick={this.onMenuClick}
                    >
                        <Menu.Item key={developersDocsPath}>
                            <Icon type="pie-chart" />
                            <span>README</span>
                        </Menu.Item>
                        <SubMenu
                            key="sub1"
                            title={(
                                <span>
                                    <Icon type="mail" />
                                    <span>Foundational topics</span>
                                </span>
                            )}
                        >
                            <Menu.Item key="2">Intro to BTCU</Menu.Item>
                            <Menu.Item key="3">Accounts</Menu.Item>
                            <Menu.Item key="4">Transactions</Menu.Item>
                            <Menu.Item key="5">Blocks</Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="sub2"
                            title={(
                                <span>
                                    <Icon type="appstore" />
                                    <span>BTCU stack</span>
                                </span>
                            )}
                        >
                            <Menu.Item key="6">Option 9</Menu.Item>
                            <Menu.Item key="7">Option 10</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <div
                    onClick={this.toggleCollapsed}
                    className={collapsed ? 'toggleBtnActive' : 'toggleBtn'}
                >
                    <Icon
                        className="trigger"
                        type={collapsed ? 'menu-unfold' : 'menu-fold'}
                    />
                    {!collapsed ? 'Collapse sidebar' : null}
                </div>
            </div>
        );
    }
}

Aside.defaultProps = {
    t: () => {},
    history: {},
};

Aside.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
};

const mapStateToProps = state => {
    const {
        currentLocale: { locale },
    } = state;

    return {
        locale,
    };
};

export default compose(withTranslation(), connect(mapStateToProps), withRouter)(Aside);
