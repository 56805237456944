import React from 'react';
import { useTranslation } from 'react-i18next';
import AmbassadorsLayout from '../../../layouts/ambassadors';
import style from './ambassadors.module.scss';

const Ambassadors = () => {
    const { t } = useTranslation();

    return (
        <div className={style.ambassadors} id="ambassadors">
            <div className={style.ambassadors__wrapper}>
                <h2 className={style.ambassadors__title}>
                    {t('headings.ambassadorsSubheading')}
                </h2>
                <div className={style.ambassadors__rightSide}>
                    <p className={style.ambassadors__rightSide_title}>
                        BTCU <span>{t('headings.ambassadorsSubheading')}</span>
                    </p>
                    <AmbassadorsLayout
                        wrapperActiveStyles={style.team__activeContainer}
                        wrapperStyles={style.team__container}
                        contentStyles={style.ambassadors__content}
                        photoActiveStyles={style.ambassadors__content_photoActive}
                        photoStyles={style.ambassadors__content_photo}
                        descriptionWrStyles={style.ambassadors__content_descriptionWr}
                        itemStyles={style.ambassadors__content_item}
                        itemBlurStyles={style.ambassadors__content_itemBlur}
                        descriptionActiveStyles={
                            style.ambassadors__content_descriptionActive
                        }
                        descriptionStyles={style.ambassadors__content_description}
                        socialsStyles={style.ambassadors__socials}
                        linkedInStyles={style.ambassadors__socials_linkedIn}
                        facebookStyles={style.ambassadors__socials_facebook}
                        seeMoreStyles={style.seeMore}
                        nameStyles={style.ambassadors__content_name}
                        containerStyles={style.ambassadors__content_container}
                    />
                </div>
            </div>
        </div>
    );
};

export default Ambassadors;
