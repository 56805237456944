/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from '../../../utils';
import socialData from '../../../constants/social-data';
import background from './images/firstScreenBg.svg';
import mainPic from './images/mainPic.png';
import mainPicMobile from './images/mainPicMobile.png';
import style from './store-page.module.scss';

class StorePage extends Component {
    static defaultProps = {
        t: () => {},
    };

    static propTypes = {
        t: PropTypes.func,
    };

    state = {};

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className={style.firstScreen}>
                    <img
                        className={style.firstScreen__backgroundImg}
                        src={background}
                        alt="background"
                    />
                    <h1 className={style.firstScreen__title}>{t('headings.store')}</h1>
                    <h3 className={style.firstScreen__subTitle}>
                        {t('texts.storeText')}
                    </h3>
                    <div className={style.socialIcons}>
                        {socialData.map(item => {
                            const { img, link } = item;

                            return (
                                <a
                                    key={link}
                                    className={style.socialIcons__link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={link}
                                >
                                    {img}
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div className={style.container}>
                    <div className={style.container__wrapper}>
                        <h2 className={style.container__title}>
                            {t('howItWorks.about')}
                        </h2>
                        <div className={style.container__rightSide}>
                            <p
                                className={style.container__rightSide_title}
                                dangerouslySetInnerHTML={{
                                    __html: t('headings.BTCUMarket'),
                                }}
                            />
                            <div className={style.container__content}>
                                <p
                                    className={style.container__content_text}
                                    dangerouslySetInnerHTML={{
                                        __html: t('texts.BTCUMarketText'),
                                    }}
                                />
                            </div>
                            <div className={style.mainPic}>
                                <img
                                    className={style.mainPic__img}
                                    src={mainPic}
                                    alt="mainPic"
                                />
                                <img
                                    className={style.mainPic__imgMobile}
                                    src={mainPicMobile}
                                    alt="mainPic"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default compose(withTranslation())(StorePage);
