import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DocsPage from './docs-page';
import { developersDocsPath } from '../../../constants';
import Aside from './aside';
import style from './developers.module.scss';

const DevelopersView = () => (
    <div className={style.container}>
        <Aside />
        <Switch>
            <Route path={developersDocsPath} component={DocsPage} exact />
        </Switch>
    </div>
);

export default DevelopersView;
