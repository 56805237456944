import React, { Fragment, Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import ArrowRight from '../../assets/images/icons/arrow-right';
import { mobileSublinksActions } from '../../../actions/mobile-sublinks.actions';
import {
    developersIntroPath,
    explorerPath,
    walletPath,
    whoWeArePath,
    howItWorksPath,
    orionPath,
    storePath,
    partnershipPath,
    communityPath,
    airDropPath,
    bugBountyPath,
    GITHUB_CONSTANTS,
} from '../../../constants';
import mobileWidth from '../../../helpers/mobile-width';
import { compose } from '../../../utils';

class ListOfLinks extends Component {
    static defaultProps = {
        t: () => {},
        setSubLinks: () => {},
        classNameList: '',
        classNameItem: '',
        classNameSubLinks: '',
        mobileSublinks: '',
    };

    static propTypes = {
        t: PropTypes.func,
        setSubLinks: PropTypes.func,
        classNameList: PropTypes.string,
        classNameItem: PropTypes.string,
        classNameSubLinks: PropTypes.string,
        mobileSublinks: PropTypes.string,
    };

    selectCategory = id => {
        const { setSubLinks } = this.props;
        if (mobileWidth()) {
            setSubLinks(id);
        }
    };

    render() {
        const {
            t,
            classNameList,
            classNameItem,
            classNameSubLinks,
            mobileSublinks,
        } = this.props;

        const links = [
            {
                id: '1',
                name: t('headings.aboutUsSubheading'),
                path: '/',
                subLinks: [
                    {
                        title: t('aboutUs.whoWeAreText'),
                        subPath: whoWeArePath,
                        border: false,
                        isExternalLink: false,
                    },
                    {
                        title: t('aboutUs.howItWorksText'),
                        subPath: howItWorksPath,
                        border: false,
                        isExternalLink: false,
                    },
                    {
                        title: t('headings.explorer'),
                        subPath: explorerPath,
                        border: false,
                        isExternalLink: false,
                    },
                    {
                        title: t('headings.wallet'),
                        subPath: walletPath,
                        border: false,
                        isExternalLink: false,
                    },
                    {
                        title: t('headings.store'),
                        subPath: storePath,
                        border: false,
                        isExternalLink: false,
                    },
                ],
            },
            {
                id: '2',
                name: t('aboutUs.developers'),
                path: '/',
                subLinks: [
                    {
                        title: t('headings.intro'),
                        subPath: developersIntroPath,
                        border: false,
                        isExternalLink: false,
                    },
                    {
                        title: t('headings.docsAndSources'),
                        subPath: GITHUB_CONSTANTS,
                        border: false,
                        isExternalLink: true,
                    },
                    {
                        title: t('headings.bugBountyText'),
                        subPath: bugBountyPath,
                        border: false,
                        isExternalLink: false,
                    },
                    {
                        title: 'Orion',
                        subPath: orionPath,
                        border: false,
                        isExternalLink: false,
                    },
                ],
            },
            {
                id: '3',
                name: t('aboutUs.partnership'),
                path: partnershipPath,
                subLinks: null,
            },
            {
                id: '4',
                name: t('headings.communitySubheading'),
                path: communityPath,
                subLinks: null,
            },
            {
                id: '5',
                name: 'AirDrop',
                path: airDropPath,
                subLinks: null,
            },
        ];

        const borderStyle = {
            borderBottom: '0.072vw solid rgba(38, 38, 66, 0.1)',
        };

        const navLinkColor = {
            color: '#FF2911',
        };

        const activeSublinks = links.find(item => item.id === mobileSublinks);

        if (activeSublinks) {
            return (
                <ul className={classNameSubLinks}>
                    {activeSublinks.subLinks.map(list => {
                        const {
                            title, subPath, border, isExternalLink,
                        } = list;

                        return (
                            <li key={title}>
                                {isExternalLink ? (
                                    <a
                                        href={subPath}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {title}
                                    </a>
                                ) : (
                                    <NavLink activeStyle={navLinkColor} to={subPath}>
                                        {title}
                                    </NavLink>
                                )}
                                {border ? <div style={borderStyle} /> : null}
                            </li>
                        );
                    })}
                </ul>
            );
        }

        return (
            <ul className={classNameList}>
                {links.map(item => {
                    const {
                        name, path, id, subLinks,
                    } = item;

                    return (
                        <li key={id} className={classNameItem}>
                            {!subLinks ? (
                                <NavLink activeStyle={navLinkColor} to={path}>
                                    {name}
                                </NavLink>
                            ) : (
                                <div onClick={() => this.selectCategory(id)}>
                                    {name} <ArrowRight />
                                </div>
                            )}
                            {!mobileWidth() && subLinks ? (
                                <div className={classNameSubLinks}>
                                    {subLinks.map(list => {
                                        const {
                                            title,
                                            subPath,
                                            border,
                                            isExternalLink,
                                        } = list;

                                        return (
                                            <Fragment key={title}>
                                                {isExternalLink ? (
                                                    <a
                                                        href={subPath}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {title}
                                                    </a>
                                                ) : (
                                                    <NavLink
                                                        activeStyle={navLinkColor}
                                                        to={subPath}
                                                    >
                                                        {title}
                                                    </NavLink>
                                                )}
                                                {border ? (
                                                    <div style={borderStyle} />
                                                ) : null}
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </li>
                    );
                })}
            </ul>
        );
    }
}

const mapStateToProps = state => {
    const {
        mobileSublinks: { data: mobileSublinks },
    } = state;

    return {
        mobileSublinks,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setSubLinks: data => mobileSublinksActions.set(data),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListOfLinks);
