import { LOADER_CONSTANTS } from '../constants';

const openLoader = () => ({
    type: LOADER_CONSTANTS.OPEN_LOADER,
});

const closeLoader = () => ({
    type: LOADER_CONSTANTS.CLOSE_LOADER,
});


export {
    openLoader,
    closeLoader,
};
