import React from 'react';
import { useTranslation } from 'react-i18next';
import TeamLayout from '../../../layouts/team';
import style from '../ambassadors/ambassadors.module.scss';

const Team = () => {
    const { t } = useTranslation();
    return (
        <div className={style.team} id="team">
            <div className={style.team__wrapper}>
                <h2 className={style.team__title}>{t('headings.aboutUs')}</h2>
                <div className={style.team__rightSide}>
                    <p className={style.team__rightSide_title}>
                        BTCU <span>{t('headings.team')}</span>
                    </p>
                    <TeamLayout
                        wrapperActiveStyles={style.team__activeContainer}
                        wrapperStyles={style.team__container}
                        contentStyles={style.team__content}
                        photoActiveStyles={style.team__content_photoActive}
                        photoStyles={style.team__content_photo}
                        descriptionWrStyles={style.team__content_descriptionWr}
                        itemStyles={style.team__content_item}
                        itemBlurStyles={style.team__content_itemBlur}
                        descriptionActiveStyles={style.team__content_descriptionActive}
                        descriptionStyles={style.team__content_description}
                        socialsStyles={style.team__socials}
                        linkedInStyles={style.team__socials_linkedIn}
                        facebookStyles={style.team__socials_facebook}
                        seeMoreStyles={style.seeMore}
                        positionStyles={style.team__content_position}
                        nameStyles={style.team__content_name}
                        containerStyles={style.team__content_container}
                    />
                </div>
            </div>
        </div>
    );
};

export default Team;
