/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../../i18n';
import konstantinVaksin from '../../assets/images/team/Konstantin_Vaksin.jpg';
import ivanIvanov from '../../assets/images/team/Ivan_Ivanov.jpg';
import sergeyBelets from '../../assets/images/team/Sergey_Belets.jpg';
import ericAlexandre from '../../assets/images/team/Eric-Alexandre.jpg';
import nikolayShkilev from '../../assets/images/team/Nikolay_Shkilev.jpg';
import leonAcosta from '../../assets/images/team/leonAcosta.png';
import xavierCalloch from '../../assets/images/team/xavierCalloch.png';
import serafimaSemkina from '../../assets/images/team/serafimaSemkina.png';
import javedKhattak from '../../assets/images/team/javedKhattak.png';
import simonCocking from '../../assets/images/team/simonCocking .png';
import hiltonSupra from '../../assets/images/team/hiltonSupra .png';
import patrick from '../../assets/images/team/patrick.png';
import linkedInIcon from '../../assets/images/icons/linkedIn-icon.svg';
import facebookIcon from '../../assets/images/icons/facebook_icon.svg';
import moreIcon from '../../assets/images/icons/more_icon.svg';
import hideIcon from '../../assets/images/icons/hide-icon.svg';

const AmbassadorsLayout = ({
    contentStyles,
    photoActiveStyles,
    photoStyles,
    descriptionWrStyles,
    itemStyles,
    itemBlurStyles,
    descriptionActiveStyles,
    descriptionStyles,
    socialsStyles,
    linkedInStyles,
    facebookStyles,
    seeMoreStyles,
    nameStyles,
    containerStyles,
    wrapperActiveStyles,
    wrapperStyles,
}) => {
    const { t } = useTranslation();
    const [activeSeeMore, setSeeMore] = useState('');

    const expand = name => {
        setSeeMore(name);
    };

    const collapse = () => {
        setSeeMore('');
    };

    const data = [
        {
            name: 'Ivan Ivanov',
            img: ivanIvanov,
            linkedIn: 'https://hk.linkedin.com/in/ivanvalerevich',
            facebook: '',
            description: t('ambassadors.ivanIvanov'),
        },
        {
            name: 'Eric Alexandre',
            img: ericAlexandre,
            linkedIn: 'https://sg.linkedin.com/in/eric-alexandre-2184315',
            facebook: '',
            description: t('ambassadors.ericAlexandre'),
        },
        {
            name: 'Sergey Belets',
            img: sergeyBelets,
            linkedIn: 'https://ru.linkedin.com/in/sergey-belets-181b4a46',
            facebook: '',
            description: t('ambassadors.sergeyBelets'),
        },
        {
            name: 'Leon Acosta',
            img: leonAcosta,
            linkedIn: 'https://www.linkedin.com/in/lnacosta91/',
            facebook: '',
            description: t('ambassadors.leonAcosta'),
        },
        {
            name: 'Xavier Calloc’h',
            img: xavierCalloch,
            linkedIn: 'https://www.linkedin.com/in/xavierc3/',
            facebook: '',
            description: t('ambassadors.xavierCalloch'),
        },
        {
            name: 'Javed Khattak',
            img: javedKhattak,
            linkedIn: 'https://www.linkedin.com/in/javedkhattak/',
            facebook: '',
            description: t('ambassadors.javedKhattak'),
        },
        {
            name: 'Patrick ',
            img: patrick,
            linkedIn: '',
            facebook: '',
            description: t('ambassadors.patrick'),
        },
        {
            name: 'Simon Cocking ',
            img: simonCocking,
            linkedIn: 'https://www.linkedin.com/in/simon-cocking-20540135/',
            facebook: '',
            description: t('ambassadors.simonCocking'),
        },

        {
            name: 'Hilton Supra',
            img: hiltonSupra,
            linkedIn: 'https://www.linkedin.com/in/hilton-supra-0140241a/',
            facebook: '',
            description: t('ambassadors.hiltonSupra'),
        },
        {
            name: 'Serafima Semkina',
            img: serafimaSemkina,
            linkedIn: 'https://www.linkedin.com/in/serafima-semkina-84251031/',
            facebook: '',
            description: t('ambassadors.serafimaSemkina'),
        },
        {
            name: 'Konstantin Vaksin',
            img: konstantinVaksin,
            linkedIn:
                'https://www.linkedin.com/in/konstantin-vaksin-57ba2b3/?originalSubdomain=be',
            facebook: '',
            description: t('ambassadors.konstantinVaksin'),
        },
        {
            name: 'Nikolay Shkilev',
            img: nikolayShkilev,
            linkedIn: 'https://ru.linkedin.com/in/vipmentor',
            facebook: '',
            description: t('ambassadors.nikolayShkilev'),
        },
    ];


    const contentLength = i18n.language === 'cn' ? 100 : 170;

    return (
        <div className={contentStyles}>
            {data.map(item => {
                const {
                    name, img, linkedIn, facebook, description,
                } = item;

                const isActive = activeSeeMore === name;

                const photoStyle = isActive ? photoActiveStyles : photoStyles;

                const descriptionWrStyle = isActive ? descriptionWrStyles : '';

                let itemstyle = itemStyles;

                if (activeSeeMore && !isActive && window.innerWidth > 499) {
                    itemstyle = classNames(itemStyles, itemBlurStyles);
                }

                let descroptionStyle = descriptionActiveStyles;
                let descriptionText = description;

                if (description.length > contentLength && !isActive) {
                    descroptionStyle = descriptionStyles;
                }

                if (description.length > contentLength) {
                    descriptionText = `${description.slice(0, contentLength)}...`;
                }

                if (isActive) {
                    descriptionText = description;
                }

                const socials = (
                    <div className={socialsStyles}>
                        {linkedIn ? (
                            <a
                                href={linkedIn}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={linkedInStyles}
                            >
                                <img src={linkedInIcon} alt="linkedIn" />
                            </a>
                        ) : null}

                        {facebook ? (
                            <a
                                href={facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={facebookStyles}
                            >
                                <img src={facebookIcon} alt="facebook" />
                            </a>
                        ) : null}
                    </div>
                );

                const seemore = (
                    <div style={{ cursor: 'pointer' }}>
                        {isActive ? (
                            <div className={seeMoreStyles} onClick={collapse}>
                                <p>{t('buttons.hide')}</p>
                                <img src={hideIcon} alt="hide" />
                            </div>
                        ) : (
                            <div className={seeMoreStyles} onClick={() => expand(name)}>
                                <p>{t('buttons.seeMore')}</p>
                                <img src={moreIcon} alt="More" />
                            </div>
                        )}
                    </div>
                );

                const cardStyle = isActive
                    ? classNames(wrapperActiveStyles, wrapperStyles)
                    : wrapperStyles;

                return (
                    <div key={name} className={cardStyle}>
                        <div className={itemstyle} tabIndex="0" onBlur={collapse}>
                            <div>
                                <img src={img} alt="logo" className={photoStyle} />
                            </div>
                            <p className={nameStyles}>{name}</p>
                            {socials}
                            <div className={containerStyles}>
                                <div className={descriptionWrStyle}>
                                    <p
                                        className={descroptionStyle}
                                        dangerouslySetInnerHTML={{
                                            __html: descriptionText,
                                        }}
                                    />
                                    {description.length > contentLength ? seemore : null}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

AmbassadorsLayout.defaultProps = {
    contentStyles: '',
    photoActiveStyles: '',
    photoStyles: '',
    descriptionWrStyles: '',
    itemStyles: '',
    itemBlurStyles: '',
    descriptionActiveStyles: '',
    descriptionStyles: '',
    socialsStyles: '',
    linkedInStyles: '',
    facebookStyles: '',
    seeMoreStyles: '',
    nameStyles: '',
    containerStyles: '',
    wrapperActiveStyles: '',
    wrapperStyles: '',
};

AmbassadorsLayout.propTypes = {
    contentStyles: PropTypes.string,
    photoActiveStyles: PropTypes.string,
    photoStyles: PropTypes.string,
    descriptionWrStyles: PropTypes.string,
    itemStyles: PropTypes.string,
    itemBlurStyles: PropTypes.string,
    descriptionActiveStyles: PropTypes.string,
    descriptionStyles: PropTypes.string,
    socialsStyles: PropTypes.string,
    linkedInStyles: PropTypes.string,
    facebookStyles: PropTypes.string,
    seeMoreStyles: PropTypes.string,
    nameStyles: PropTypes.string,
    containerStyles: PropTypes.string,
    wrapperActiveStyles: PropTypes.string,
    wrapperStyles: PropTypes.string,
};

export default AmbassadorsLayout;
