/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './about.module.scss';

const About = () => {
    const { t } = useTranslation();

    const data = [
        {
            label: t('headings.partners'),
            title: t('partnership.university'),
            text: t('partnership.universityText'),
            isRed: false,
        },
        {
            label: t('headings.partners'),
            title: t('partnership.advisors'),
            text: t('partnership.advisorsText'),
            isRed: false,
        },
        {
            label: t('headings.partners'),
            title: t('partnership.validators'),
            text: t('partnership.validatorsText'),
            isRed: true,
        },
        {
            label: t('headings.partners'),
            title: t('partnership.masternode'),
            text: t('partnership.masternodeText'),
            isRed: true,
        },
    ];

    return (
        <div className={style.about}>
            {data.map(item => {
                const {
                    label, title, text, isRed,
                } = item;

                const labelStyle = isRed ? style.about__titleRed : style.about__title;
                const rightSideTitleStyle = isRed
                    ? style.about__rightSide_titleRed
                    : style.about__rightSide_title;

                return (
                    <div key={title} className={style.about__wrapper}>
                        <h2 className={labelStyle}>{label}</h2>
                        <div className={style.about__rightSide}>
                            <p className={rightSideTitleStyle}>{title}</p>
                            <div className={style.about__content}>
                                <p
                                    className={style.about__content_text}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default About;
