import notification from '../helpers/notifications';
import { POST_SUBSCRIBE_NEWS, EMAIL_ALREADY_TAKEN } from '../constants';

const postSubscribeNewsRequested = () => ({
    type: POST_SUBSCRIBE_NEWS.POST_SUBSCRIBE_NEWS_REQUEST,
});

const postSubscribeNewsLoaded = data => ({
    type: POST_SUBSCRIBE_NEWS.POST_SUBSCRIBE_NEWS_SUCCESS,
    payload: data,
});

const postSubscribeNewsError = error => ({
    type: POST_SUBSCRIBE_NEWS.POST_SUBSCRIBE_NEWS_FAILURE,
    payload: error,
});

const fetchSubscribeNewsAction = postService => (values, t) => dispatch => {
    dispatch(postSubscribeNewsRequested());
    postService
        .subscribeNews(values)
        .then(data => {
            dispatch(postSubscribeNewsLoaded(data));
            notification('', t('successNotification'), 'success');
        })
        .catch(error => {
            dispatch(postSubscribeNewsError(error.response.data.message));
            const {
                response: { data: { message = t('error.title') } = {} } = {},
            } = error;
            let errorMessage = message;

            if (message === EMAIL_ALREADY_TAKEN) {
                errorMessage = t('error.emailAlreadyTaken');
            }

            notification('', errorMessage, 'danger');
        });
};

export default fetchSubscribeNewsAction;
