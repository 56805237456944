import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListOfLinks from './header-links';
import MobileMenu from './mobile-menu';
import { compose } from '../../../utils';
import SelectLangeage from '../../language';
import { developersDocsPath } from '../../../constants';
import logo from '../../assets/images/logos/btcu.svg';
import logoDark from '../../assets/images/logos/btcu-dark.svg';
import style from './header.module.scss';

const Header = ({ isOpen, location: { pathname } }) => {
    const narrowHeaderLinks = [developersDocsPath];
    const isNarrow = narrowHeaderLinks.includes(pathname);
    const headerStyle = isNarrow
        ? classNames(style.header, style.headerNarrow)
        : style.header;

    return (
        <header className={headerStyle}>
            <div className={style.header__leftSide}>
                <div className={style.header__logo}>
                    <Link to="/">
                        <img
                            className={style.header__logo_img}
                            src={isOpen ? logoDark : logo}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className={style.header__linksWrapper}>
                    <ListOfLinks
                        classNameList={style.header__links}
                        classNameItem={style.header__links_item}
                        classNameSubLinks={style.header__subLinks}
                    />
                </div>
            </div>
            <div className={style.header__rightSide}>
                <SelectLangeage isNarrow={isNarrow} />
            </div>
            <MobileMenu />
        </header>
    );
};

Header.defaultProps = {
    isOpen: false,
    location: {},
};

Header.propTypes = {
    isOpen: PropTypes.bool,
    location: PropTypes.object,
};

const mapStateToProps = state => {
    const {
        drawer: { isOpen },
    } = state;

    return {
        isOpen,
    };
};

export default compose(connect(mapStateToProps), withRouter)(Header);
