/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { communityPath, orionPath } from '../../../../constants';
import listIcon from './images/list-icon.svg';
import style from './how-start.module.scss';

const HowStart = () => {
    const { t } = useTranslation();

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('howItWorks.about')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.howStartUsing'),
                        }}
                    />
                    <div className={style.about__content}>
                        <div className={style.about__list}>
                            <img src={listIcon} alt="listIcon" />
                            <p className={style.about__content_text}>
                                {t('texts.howStartUsingText1')}
                            </p>
                        </div>
                        <div className={style.about__list}>
                            <img src={listIcon} alt="listIcon" />
                            <p className={style.about__content_text}>
                                {t('texts.howStartUsingText2')}{' '}
                                <Link to={communityPath}>({t('buttons.learnMore')})</Link>
                            </p>
                        </div>
                        <div className={style.about__list}>
                            <img src={listIcon} alt="listIcon" />
                            <div className={style.about__content_text}>
                                {t('texts.howStartUsingText3')}{' '}
                                <Link to={orionPath}>Orion</Link>
                                {'  '}
                                <p dangerouslySetInnerHTML={{
                                    __html: t('texts.howStartUsingText4'),
                                }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowStart;
