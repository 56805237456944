import React, { Fragment } from 'react';
import FirstScreen from './first-screen';
import About from './about';
import JoinUs from './join-us';

const Partnership = () => (
    <Fragment>
        <FirstScreen />
        <About />
        <JoinUs />
    </Fragment>
);

export default Partnership;
