import React, { Fragment } from 'react';
import FirstScreen from './first-screen';
import Ultimatum from './ultimatum';
import Media from './media';
import SubscribeNews from './subscribe-news';
import SubscribeDevelopers from './subscribe-developers';

const Community = () => (
    <Fragment>
        <FirstScreen />
        <Ultimatum />
        <SubscribeNews />
        <SubscribeDevelopers />
        <Media />
    </Fragment>
);

export default Community;
