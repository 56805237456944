/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import socialData from '../../../../constants/social-data';
import { EXPLORER_TESTNET } from '../../../../constants/links.constants';
import background from './images/firstScreenBg.svg';
import style from './first-screen.module.scss';

const FirstScreen = () => {
    const { t } = useTranslation();

    return (
        <div className={style.firstScreen}>
            <img
                className={style.firstScreen__backgroundImg}
                src={background}
                alt="background"
            />
            <h1 className={style.firstScreen__title}>
                BTCU <span>Orion</span>
            </h1>
            <h3 className={style.firstScreen__subTitle}>{t('texts.orionShort')}</h3>
            <div className={style.firstScreen__bottom}>
                <AnchorLink href="#wallet" className={style.firstScreen__form_buttonFilled}>
                    {t('headings.wallet')}
                </AnchorLink>
                <a
                    href={EXPLORER_TESTNET}
                    className={style.firstScreen__form_button}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('headings.explorer')}
                </a>
            </div>
            <div className={style.socialIcons}>
                {socialData.map(item => {
                    const { img, link } = item;

                    return (
                        <a
                            key={link}
                            className={style.socialIcons__link}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link}
                        >
                            {img}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default FirstScreen;
