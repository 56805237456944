/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import socialData from '../../../../constants/social-data';
import { orionPath, bugBountyPath, GITHUB_CONSTANTS } from '../../../../constants';
import background from './images/firstScreenBg.svg';
import codeIcon from './images/code.svg';
import binarisIcon from './images/binaris.svg';
import orionIcon from './images/orion.svg';
import style from './developers.module.scss';

const DevelopersPage = () => {
    const { t } = useTranslation();

    const data = [
        {
            img: codeIcon,
            title: t('headings.docsAndSources'),
            subTitile: t('texts.docsSubText'),
            link: GITHUB_CONSTANTS,
            btnText: t('buttons.start'),
            isExternalLink: true,
        },
        {
            img: binarisIcon,
            title: t('headings.bugBountyText'),
            subTitile: t('texts.bugBountySubText'),
            link: bugBountyPath,
            btnText: t('buttons.start'),
            isExternalLink: false,
        },
        {
            img: orionIcon,
            title: 'Orion',
            subTitile: t('texts.orionSubText'),
            link: orionPath,
            btnText: t('buttons.testItNow'),
            isExternalLink: false,
        },
    ];
    return (
        <Fragment>
            <div className={style.firstScreen}>
                <img
                    className={style.firstScreen__backgroundImg}
                    src={background}
                    alt="background"
                />
                <h1
                    className={style.firstScreen__title}
                    dangerouslySetInnerHTML={{ __html: t('aboutUs.forDevelopers') }}
                />
                <h3 className={style.firstScreen__subTitle}>{t('texts.btcuShort')}</h3>
                <div className={style.socialIcons}>
                    {socialData.map(item => {
                        const { img, link } = item;

                        return (
                            <a
                                key={link}
                                className={style.socialIcons__link}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={link}
                            >
                                {img}
                            </a>
                        );
                    })}
                </div>
            </div>
            <div className={style.container}>
                {data.map(item => {
                    const {
                        img, title, subTitile, link, btnText, isExternalLink,
                    } = item;

                    return (
                        <div key={img} className={style.container__item}>
                            <div>
                                <img
                                    className={style.container__item_img}
                                    src={img}
                                    alt="img"
                                />
                                <p className={style.container__item_title}>{title}</p>
                                <p className={style.container__item_subTitile}>
                                    {subTitile}
                                </p>
                            </div>
                            {isExternalLink ? (
                                <a
                                    href={link}
                                    target="_blank"
                                    className={style.container__item_button}
                                >
                                    {btnText}
                                </a>
                            ) : (
                                <Link to={link} className={style.container__item_button}>
                                    {btnText}
                                </Link>
                            )}
                        </div>
                    );
                })}
            </div>
        </Fragment>
    );
};

export default DevelopersPage;
