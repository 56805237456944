import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PostService from './services/post-service';
import { PostServiceProvider } from './components/context/post-service-context';
import App from './components/app';
import ErrorBoundry from './components/pages/error-page/error-boundry';
import { LoadingPage } from './components/pages';
import store from './store';
import './i18n';
import './components/app/app.scss';

const postService = new PostService();

ReactDOM.render(
    <Provider store={store}>
        <PostServiceProvider value={postService}>
            <Suspense fallback={<LoadingPage />}>
                <ErrorBoundry>
                    <App />
                </ErrorBoundry>
            </Suspense>
        </PostServiceProvider>
    </Provider>,
    document.getElementById('root'),
);
