/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    developersIntroPath,
    whoWeArePath,
    howItWorksPath,
    partnershipPath,
    communityPath,
    airDropPath,
} from '../../../../constants';
import whoWeAreIcon from './images/1.svg';
import howItWorksIcon from './images/how-it-works.svg';
import forDeveloperIcon from './images/2.svg';
import partnershipIcon from './images/3.svg';
import communityIcon from './images/4.svg';
import productsIcon from './images/5.svg';
import style from './about-us.module.scss';

const AboutUs = () => {
    const { t } = useTranslation();

    const data = [
        {
            img: whoWeAreIcon,
            text: t('aboutUs.whoWeAre'),
            subTitle: t('texts.whoWeAreShort'),
            link: whoWeArePath,
        },
        {
            img: howItWorksIcon,
            text: t('aboutUs.howItWorks'),
            subTitle: t('texts.howitworksShort'),
            link: howItWorksPath,
        },
        {
            img: productsIcon,
            text: 'AirDrop',
            subTitle: t('texts.airDropShort'),
            link: airDropPath,
        },
        {
            img: partnershipIcon,
            text: t('aboutUs.partnership'),
            subTitle: t('partnership.shortDescription'),
            link: partnershipPath,
        },
        {
            img: forDeveloperIcon,
            text: t('aboutUs.forDevelopers'),
            subTitle: t('texts.btcuShort'),
            link: developersIntroPath,
        },
        {
            img: communityIcon,
            text: t('headings.communitySubheading'),
            subTitle: t('texts.communityText'),
            link: communityPath,
        },
    ];

    return (
        <div className={style.aboutUs} id="aboutUs">
            <div className={style.aboutUs__wrapper}>
                <h2 className={style.aboutUs__title}>
                    {t('headings.aboutUsSubheading')}
                </h2>
                <div>
                    <div className={style.aboutUs__rightSide}>
                        <p className={style.aboutUs__rightSide_title}>
                            {t('headings.aboutUsMain')}{' '}
                            <span>{t('headings.blockchain')}</span>
                        </p>
                        <div className={style.aboutUs__content}>
                            <p className={style.aboutUs__content_text}>
                                {t('texts.aboutUs')}
                            </p>
                            <p className={style.aboutUs__content_text}>
                                {t('texts.aboutUs2')}
                            </p>
                        </div>
                    </div>
                    <div className={style.wrapper}>
                        {data.map(item => {
                            const {
                                img, text, subTitle, link,
                            } = item;

                            return (
                                <div key={img} className={style.wrapper__item}>
                                    <img
                                        src={img}
                                        alt="img"
                                        className={style.wrapper__item_img}
                                    />
                                    <span
                                        className={style.wrapper__item_title}
                                        dangerouslySetInnerHTML={{ __html: text }}
                                    />
                                    <span className={style.wrapper__item_subTitle}>
                                        {`${subTitle.slice(0, 43)}...`}
                                    </span>
                                    <Link
                                        key={img}
                                        to={link}
                                        className={style.wrapper__item_btn}
                                    >
                                        {t('buttons.seeMore')}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
