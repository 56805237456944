import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default class PostService {
    getResource = async (url, data, method) => {
        const options = {
            url,
            method,
            data,
        };
        const response = await axios(options);

        if (response.status !== 200) {
            throw new Error(`${url},received ${response.status}`);
        }
        return response.data;
    };

    logout = () => {
        localStorage.removeItem('user');
        document.location.reload(true);
    };

    subscribeNews = async data => {
        const res = await this.getResource(`${BASE_URL}/mailing`, data, 'POST');
        return res;
    };
}
