/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './term-of-use.module.scss';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className={style.privacyPolicy}>
            <h1 className={style.privacyPolicy__mainTitle}>
                BITCOIN ULTIMATUM <br /> <br />
                {t('headings.termOfUse')}
            </h1>
            <p className={style.privacyPolicy__date}>Last Updated: September 2, 2021</p>
            <div className={style.privacyPolicy__text}>
                <p>
                    These Terms of Service (these “Terms”) govern your access to and use
                    of certain products associated with the Bitcoin Ultimatum (“BTCU”)
                    blockchain network, including the Website, Explorer and BTCU Wallet,
                    in each case as defined below (collectively, the “Products”).
                </p>
                <br />
                <p>
                    By accessing, using, or downloading the Products, or by accepting
                    these Terms, you agree to be bound by these Terms without
                    modifications. These Terms form a legally binding agreement between
                    you as a user of Products (“you”, “your”) and Profit Foundation LTD, a
                    company established under the laws of Singapore, having registered
                    address at 73 Upper Paya Lebar Road #06-01C Centro Bianco, Singapore,
                    534818 (“we”, “us”, “our”). If you are unwilling or unable to be bound
                    by these Terms, you shall not use the Products and shall cease any
                    such use immediately.
                </p>
                <br />
                <p>
                    If you are entering into these Terms on behalf of an entity, you
                    accept these Terms on behalf of such entity and on your own behalf. In
                    such case, the references in these Terms to “you” shall refer to both
                    the above entity on whose behalf an individual using the Products is
                    acting, and to such individual.
                </p>
                <br />
                <p>
                    Please read these Terms carefully as they constitute a legally binding
                    agreement that affects your legal rights and obligations. Section 15
                    of these Terms contains the binding arbitration and waiver of class
                    actions provisions. In accordance with Section 12, our liability to
                    you is limited and certain types of liability are excluded.{' '}
                </p>
                <br />
                <p>
                    The Products include the (1) website available at
                    <a href="https://btcu.io/" target="_blank" rel="noopener noreferrer">
                        https://btcu.io/
                    </a>
                    (the “Website”), (2) BTCU explorer available at
                    <a
                        href="https://explorer.btcu.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://explorer.btcu.io/
                    </a>
                    (the “Explorer”), and (3) BTCU wallet software, including the
                    web-based application available at
                    <a
                        href="https://wallet.btcu.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://wallet.btcu.io/
                    </a>
                    , browser extension (plugin), desktop and mobile applications (the
                    “BTCU Wallet”).
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>1. ELIGIBILITY</h4>
                <p className={style.privacyPolicy__content}>
                    1.1. To be eligible to access and use the Products, you shall: <br />
                    <br />
                    - be able to form a legally binding agreement with us on terms herein
                    set forth;
                    <br />
                    <br />
                    - be at least 18 (eighteen) years of age, or of such higher age
                    required to enter into a binding agreement with us on the terms set
                    out herein according to the laws of the jurisdiction where you reside;
                    <br />
                    <br />
                    - use the Products for your business purposes only, and not as a
                    consumer, and you hereby further agree and acknowledge that the
                    Products are available for your commercial use only.
                    <br />
                    <br />
                    1.2. If you are accessing or using the Products on behalf of a legal
                    entity, you hereby represent and warrant that (i) such legal entity is
                    duly organised and validly existing under the applicable laws of the
                    jurisdiction of its organisation, and (ii) you are duly authorised by
                    such legal entity to act on its behalf.
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>2. LICENSE</h4>
                <p className={style.privacyPolicy__content}>
                    2.1. Subject to your compliance with these Terms, we hereby grant you
                    a limited, non-transferable, non-exclusive, revocable,
                    non-sublicensable license (right) to access the Products, and make
                    personal, non-commercial use of the Products and content provided
                    therein in a manner permitted under these Terms. <br />
                    <br />
                    2.2. Should you breach any of terms contained herein, the license
                    granted pursuant to Clause 2.1 shall automatically terminate and
                    cease. Any rights not expressly granted to you hereunder are reserved
                    by us and/or respective rightsholders.
                    <br />
                    <br />
                    2.3. You may make references to or quotations of the content of the
                    Products only if such references or quotations are accompanied by
                    reference to us and an active hyperlink to the Products, provided,
                    however, that we may require the removal of any such references or
                    quotations at any time with or without a reason.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>3. ACCEPTABLE USE</h4>
                <p className={style.privacyPolicy__content}>
                    You hereby represent and warrant that you: <br />
                    <br />
                    - are eligible and have full capacity for using the Products under the
                    laws of the country of your residence and any such use does not
                    infringe any rights of third parties; <br />
                    <br />
                    - are of the necessary legal age, in accordance with the laws of the
                    country of your residence, to use the Products; <br />
                    <br />
                    - are not a subject of any sanctions administered or enforced by any
                    country, government, or international authority nor are you a resident
                    or established (in the case of a corporate entity) in a country or
                    territory that is the subject of a country-wide or territory-wide
                    sanction imposed by any country, government, or international
                    authority; <br />
                    <br />
                    - will use the Products only for legitimate purposes and you will not
                    use the Products for any illegal or fraudulent activity; <br />
                    <br />
                    - will only use and hold digital assets, including cryptocurrencies
                    and cryptographic tokens, that are from legitimate sources and were
                    lawfully acquired. <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>4. PROHIBITED USE</h4>
                <p className={style.privacyPolicy__content}>
                    You shall not: <br />
                    <br />
                    - use the Products for any illegal purposes, including, but not
                    limited to, terrorism financing and money laundering;
                    <br />
                    <br />
                    - circumvent or attempt to circumvent any access or functionality
                    restrictions or limitations with respect to the Products or underlying
                    software;
                    <br />
                    <br />
                    - use robots, spiders, or other similar automated means that are not
                    expressly allowed hereunder to access the Products or their
                    components;
                    <br />
                    <br />
                    - use the Products in any manner that could disrupt, interfere with,
                    or inhibit other users from using the Products, or that could disable,
                    impair, or harm the functioning of the Products or respective servers;
                    <br />
                    <br />
                    - decompile, disassemble, reverse engineer, “frame”, “mirror”, or
                    create derivative works from the Products or related content without
                    our prior written authorisation.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>5. BTCU WALLET</h4>
                <p className={style.privacyPolicy__content}>
                    5.1. The BTCU Wallet interface allows you to set up and manage a
                    digital wallet (the “Digital Wallet”), or have an existing Digital
                    Wallet, which constitutes a Third-Party Service as defined below,
                    connected to the BTCU Wallet. The Digital Wallet constitutes a pair of
                    public and private cryptographic keys that can be used to receive and
                    dispose of the digital assets. <br />
                    <br />
                    5.2. When you use the BTCU Wallet, we do not manage, gain access to,
                    or control over your digital assets or your Digital Wallet, and we are
                    not able to do so. The BTCU Wallet is a non-custodial software wallet
                    interface that enables you to manage your Digital Wallet in a
                    user-friendly and convenient manner. <br />
                    <br />
                    5.3. You are solely responsible for maintaining the confidentiality of
                    the credentials to your Digital Wallet (including the private key and
                    mnemonic phrase) and other information that may be used to gain
                    control over the Digital Wallet (the “Credentials”). You are
                    responsible for safeguarding your Credentials. BTCU Wallet does not
                    have a feature enabling you to recover your Credentials or access to
                    the Digital Wallet. This means that if you lose your Credentials, you
                    may not be able to restore access to your Digital Wallet and assets
                    held in such Digital Wallet. We do not store your Credentials on our
                    servers, this information is encrypted and stored locally on your
                    device. <br />
                    <br />
                    5.4. Do not share your Credentials with any other person as this will
                    give such person the ability to dispose of your digital assets held in
                    such Digital Wallet. We shall not be liable or held responsible for
                    any loss or damage arising from your failure to comply with the
                    obligations to maintain the confidentiality of your Credentials.{' '}
                    <br />
                    <br />
                    5.5. We are not an intermediary in any transaction that is carried out
                    between you and any other person using the BTCU Wallet. You shall
                    carefully check the address of the recipient before making any
                    transfers and your transactions with digital assets shall be done with
                    correct blockchain addresses. <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>6. EXPLORER AND ACCOUNT</h4>
                <p className={style.privacyPolicy__content}>
                    6.1. The Explorer constitutes software that allows you to view certain
                    data about the Digital Wallets set up with, and transactions conducted
                    on, the BTCU blockchain in an easily-understandable and user friendly
                    manner. Explorer runs in an automated manner, we do not verify,
                    validate, or check any information that you may access via the
                    Explorer and do not have an ability to do so.
                    <br />
                    <br />
                    6.2. We do not make any representations and warranties as to the
                    accuracy, completeness, or timeliness of the information provided
                    within the Explorer. Always do your own research and verify data
                    obtained from the Explorer as certain information may not be accurate,
                    complete, or timely. You shall not rely on the information provided
                    via the Explorer in making any decisions and any reliance on such
                    information shall be at your own risk. The information provided via
                    the Explorer shall be deemed a Third-Party Content, as defined below.{' '}
                    <br />
                    <br />
                    6.3. Within the Explorer you may register for an account (the
                    “Account”). When you create an Account, you agree to:
                    <br />
                    <br />
                    - create a strong password that you do not use for any other website
                    or online service; <br />
                    <br />
                    - maintain and promptly update your Account information; <br />
                    <br />
                    - maintain the security of your Account by protecting your Account
                    password and restricting access to your respective devices and your
                    Account; <br />
                    <br />
                    - promptly notify us if you discover or otherwise suspect any security
                    breaches related to your Account; and
                    <br />
                    <br />
                    - take responsibility for all activities that occur under your Account
                    and accept all risks of any authorised or unauthorised access to your
                    Account, to the maximum extent permitted by law. <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    7. THIRD-PARTY CONTENT AND THIRD-PARTY SERVICES
                </h4>
                <p className={style.privacyPolicy__content}>
                    7.1. Within the Products you may view certain content provided by
                    third parties or generated from third-party sources, including, inter
                    alia, advertisements, promotions, materials, third-party data,
                    analytics, and links to third-party resources or websites (the
                    “Third-Party Content”) or interact with certain third-party software,
                    services, items, and solutions, including software wallets,
                    blockchains, tokens, blockchain liquidity pools (the “Third-Party
                    Services”).
                    <br />
                    <br />
                    7.2. We are not responsible for and do not make any warranties as to
                    the Third-Party Content or Third-Party Services, do not endorse and
                    are not responsible for any information, statements, content,
                    advertisements, goods or services, or other materials available
                    thereon.
                    <br />
                    <br />
                    7.3. We are not responsible or liable for any loss or damage of any
                    sort incurred as the result of accessing or using of any Third-Party
                    Content or Third-Party Services, and you understand that your use of
                    Third-Party Content or Third-Party Services, and your interactions
                    with third parties that are linked to or from the Products, is at your
                    own risk.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    8. INTELLECTUAL PROPERTY RIGHTS
                </h4>
                <p className={style.privacyPolicy__content}>
                    8.1. The Products contain materials and data, e.g., analytics,
                    software, technologies, texts, code, graphics, designs, trademarks,
                    logos, images provided by us or on our behalf (the “Materials”). Such
                    Materials may be owned by us or by third parties, are protected under
                    the applicable laws and may not be copied, imitated or used, in whole
                    or in part, without prior written permission of the respective right
                    owner.
                    <br />
                    <br />
                    8.2. You do not have nor obtain any rights to the Materials other than
                    those specifically indicated in these Terms. Elements of the Products
                    are protected as trade dress, trademarks and as other intellectual
                    property. Unauthorised use of the Materials may violate our rights and
                    rights of third parties.
                    <br />
                    <br />
                    8.3. In addition, our services, including all page headers, custom
                    graphics, button icons and scripts, are our service mark, trademark
                    and/or trade dress (whether registered or not) and may not be copied,
                    imitated or used, in whole or in part, without our prior written
                    permission. All other trademarks, registered trademarks, product
                    names, and company names or logos mentioned through the Products are
                    the property of their respective owners.
                    <br />
                    <br />
                    8.4. Reference to any products, services, processes or other
                    information, by name, trademark, manufacturer, and supplier or
                    otherwise does not constitute or imply endorsement, sponsorship or
                    recommendation by us.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    9. INTELLECTUAL PROPERTY RIGHTS
                </h4>
                <p className={style.privacyPolicy__content}>
                    9.1. Generally, we are not going to terminate access to the Products,
                    the Products are public and generally can be accessed by anyone. At
                    the same time, the Products’ availability and functionality depends on
                    various factors. We do not warrant or guarantee that the Products will
                    operate and/or be available at all times without disruption or
                    interruption.
                    <br />
                    <br />
                    9.2. We may terminate these Terms, same as your access to the Products
                    or any parts thereof immediately at any time without any prior notice
                    and liability.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>10. NO ADVICE</h4>
                <p className={style.privacyPolicy__content}>
                    10.1. No part of the information or content available within the
                    Products should be considered to be business, legal, financial, or tax
                    advice regarding any matters to which all or any part of such
                    information relates. You should consult your own legal, financial,
                    tax, or other professional advisor regarding this information.
                    <br />
                    <br />
                    10.2. We shall not be responsible for the accuracy of the information
                    and materials within the Products, therefore any use of such
                    information or materials is at your own discretion and risk and you
                    are solely responsible for any possible damages or losses arising from
                    such use, e.g., damages to your computer system, loss of data.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>11. NO WARRANTY</h4>
                <p className={style.privacyPolicy__content}>
                    11.1. The Products are provided “as is” and “as available”. The
                    Products are provided without warranty of any kind, express or
                    implied, including, but not limited to, the implied warranties of
                    title, non-infringement, integration, merchantability and fitness for
                    a particular purpose, and any warranties implied by any course of
                    performance or usage of trade, all of which are expressly disclaimed.
                    <br />
                    <br />
                    11.2. We do not warrant, whether expressly or impliedly, and expressly
                    disclaim any warranty and representation that: (a) any information
                    displayed in or made available through the Products or otherwise
                    communicated by us or on our behalf will be timely, accurate,
                    reliable, true or correct; (b) the Products or any related software
                    will be secure, available at any particular time or place, or will
                    work as expected; (c) the Products or any related software will be
                    maintained, developed or operated, or will continue functioning for
                    any period of time, (d) any defects or errors in the Products or
                    related software will be corrected; or (d) the Products or any related
                    software will be free of defects, bugs, viruses, or other harmful
                    components.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    12. LIMITATION OF LIABILITY
                </h4>
                <p className={style.privacyPolicy__content}>
                    12.1. Except as otherwise required by law, in no event shall we, our
                    affiliates, directors, members, shareholders, employees, contractors,
                    or agents (collectively, “Representatives”) be liable for any special,
                    indirect or consequential damages, or any other damages of any kind,
                    including but not limited to loss of use, loss of profits or loss of
                    data, whether in an action in contract, tort (including but not
                    limited to negligence) or otherwise, whether or not we or
                    Representatives were advised of the possibility of such damages,
                    arising out of or in any way connected with the use of or inability to
                    use the Products, including without limitation any damages caused by
                    or resulting from reliance by any user on any information obtained
                    from the Products, or that result from mistakes, omissions,
                    interruptions, deletion of files, hacks, theft, errors, defects,
                    viruses, delays in operation or transmission or any failure of
                    performance, whether or not resulting from a force majeure event,
                    communications failure, theft, destruction or unauthorised access to
                    our programs or services.
                    <br />
                    <br />
                    12.2. In any case, without limiting the generality of the foregoing
                    and to the maximum extent legally permissible, our and our
                    Representatives’ aggregate liability to you arising out of these Terms
                    or in any way related to your use or inability to use the Products and
                    functionality available therein shall be limited to USD 1,000 (one
                    thousand U.S. dollars).
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>13. FORCE MAJEURE</h4>
                <p className={style.privacyPolicy__content}>
                    In no event shall we be responsible or liable for any failure or delay
                    in the performance of its obligations hereunder arising out of or
                    caused by, directly or indirectly, forces beyond its control,
                    including, without limitation, flood, extraordinary weather
                    conditions, earthquake, or other act of God, fire, declared and
                    undeclared war, epidemic, pandemic, insurrection, riot, labour
                    dispute, accident, action of government, court, regulatory or other
                    authorities, including the issuance of cease and desist orders,
                    communications failure, hacker attacks, malware attacks, software
                    weaknesses, malfunctions, 51% attacks or similar attacks, power
                    failure, equipment or software malfunction or error, third-party acts
                    or omissions, or any other cause beyond our reasonable control.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>14. INDEMNITY</h4>
                <p className={style.privacyPolicy__content}>
                    You shall indemnify and hold us and our Representatives harmless from
                    and against any and all third-party claims, actions, demands, losses,
                    costs, expenses, fines, penalties, liabilities, and other amounts that
                    arise out of or related to your use of the Products, any functionality
                    available therein, or your violation of any provision of these Terms.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    15. APPLICABLE LAW AND DISPUTE RESOLUTION
                </h4>
                <p className={style.privacyPolicy__content}>
                    15.1. These Terms are governed by the laws of England and Wales.
                    <br />
                    <br />
                    15.2. With the exception to injunctive or other equitable relief for
                    the alleged unlawful use of intellectual property, including, without
                    limitation, copyrights, trademarks, trade names, logos, trade secrets
                    or patents, you hereby agree to settle and finally resolve any
                    dispute, controversy, claim, suit, action, cause of action, demand
                    and/or proceeding (collectively, “Dispute”) arising out of or in
                    connection with the Products, these Terms and any matters contemplated
                    herein in binding arbitration and in accordance with this Section 15.
                    <br />
                    <br />
                    15.3. Binding arbitration is the referral of a Dispute to a qualified
                    person(s) who will review the Dispute and make a final and binding
                    determination, by making an order, to resolve the Dispute. For any
                    Dispute that you have against us or relating to the Products, these
                    Terms or any matters contemplated herein, you shall first contact us
                    and attempt to resolve the Dispute informally by sending a notice to
                    us via email at support@btcu.io. The notice must include your name,
                    address and email, and such other information necessary to identify
                    you or assess your inquiry, describe the nature and basis of the
                    Dispute and set forth the specific relief sought.
                    <br />
                    <br />
                    15.4. If we cannot reach an agreement with you to resolve the Dispute
                    within thirty (30) days after such a notice is received, then either
                    we or you may submit the dispute to binding arbitration administered
                    by the London Court of International Arbitration, in accordance with
                    the terms set forth below.
                    <br />
                    <br />
                    15.5. Any Disputes arising out of or in connection with the Products,
                    these Terms or any matters contemplated herein, including any question
                    regarding the existence, validity, or termination of these Terms,
                    shall be referred to and finally resolved by the binding arbitration
                    under the London Court of International Arbitration rules, which rules
                    are deemed to be incorporated by reference herein. Any arbitration
                    will occur in London, UK.
                    <br />
                    <br />
                    15.6. Unless otherwise required by the applicable law, and to the
                    maximum extent permitted and possible, you, us, and the arbitrators
                    shall maintain the confidentiality of any arbitration proceedings,
                    judgments and awards, including, but not limited to, all information
                    gathered, prepared and presented for purposes of the arbitration or
                    related to the Disputes. Unless prohibited under the law, the
                    arbitrator will have the authority to make appropriate rulings to
                    safeguard confidentiality.
                    <br />
                    <br />
                    15.7. You will not and hereby waive your rights to object to the
                    arbitration prescribed herein.
                    <br />
                    <br />
                    15.8. Any Dispute arising out of or related to these Terms is personal
                    to you and us and will be resolved solely through individual
                    arbitration and will not be brought as a class arbitration, class
                    action, or any other type of representative proceeding in any
                    circumstances. There will be no class or other type or representative
                    action, whether within or outside of arbitration where an individual
                    attempts to resolve a Dispute as a representative of another
                    individual or group of individuals. To the maximum extent permitted
                    under the law, you and we hereby waive the rights to a jury trial,
                    class action arbitration, and to have any Dispute resolved in court.
                    <br />
                    <br />
                    15.9. To the maximum extent permitted under the law, you and we hereby
                    agree that any claim arising out of or related to the Products, these
                    Terms or any matters contemplated herein shall be filed within one (1)
                    year after the ground for such claim arose; if the claim is not filed
                    within this term, such claim shall be permanently barred, which means
                    that neither you, nor we will have the right to assert such claim.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>16. ENTIRE AGREEMENT</h4>
                <p className={style.privacyPolicy__content}>
                    These Terms, together with any documents incorporated herein by
                    reference, contain the entire agreement between you and us, and
                    supersede all prior and contemporaneous understandings between you and
                    us regarding the subject matters hereof.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>17. MODIFICATION</h4>
                <p className={style.privacyPolicy__content}>
                    17.1. We reserve the right, at our sole discretion, to modify or
                    replace these Terms in any manner whatsoever. In case we modify these
                    Terms, we will change the “Last Updated” date at the beginning of
                    these Terms. An up-to-date version of these Terms will be accessible
                    through the Products and it is your sole responsibility to check these
                    Terms regularly for updates.
                    <br />
                    <br />
                    17.2. Your continued use of the Products will indicate your acceptance
                    of the revised Terms and your consent to be bound thereby.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>18. COMMUNICATIONS</h4>
                <p className={style.privacyPolicy__content}>
                    18.1. You agree and consent to receive electronically all
                    communications, agreements, documents, receipts, notices and
                    disclosures (collectively, “Communications”) that we provide in
                    connection with your use of the Products. You agree that we may
                    provide Communications to you by posting them within the Products or
                    by emailing them to you at the email address you provide. Any such
                    Communication shall be deemed in writing, valid and have legal force.
                    <br />
                    <br />
                    18.2. You may electronically communicate with us by sending
                    Communications through contact forms which may be from time to time
                    available within the Products, or by sending them by email to the
                    following address support@btcu.io.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>19. PERSONAL DATA</h4>
                <p className={style.privacyPolicy__content}>
                    Your personal data is processed in accordance with the Privacy Policy
                    available at [link to the Privacy Policy].
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>20. MISCELLANEOUS</h4>
                <p className={style.privacyPolicy__content}>
                    20.1. Currently, only the English version of these Terms and
                    Communications is considered official. The English version shall
                    prevail in case of differences in translation of any documents,
                    notices or other content.
                    <br />
                    <br />
                    20.2. The invalidity or unenforceability of any provision of these
                    Terms shall not affect the validity or enforceability of any other
                    provisions of these Terms, all of which shall remain in full force and
                    effect.
                    <br />
                    <br />
                    20.3. No failure or delay to exercise any right or remedy provided
                    under these Terms or by law shall constitute a waiver of that or any
                    other right or remedy, nor shall it preclude or restrict the further
                    exercise of that or any other right or remedy. No single or partial
                    exercise of such right or remedy shall preclude or restrict the
                    further exercise of that or any other right or remedy.
                    <br />
                    <br />
                    20.4. You may not assign or transfer any of your rights or obligations
                    under these Terms without our prior written consent, including by
                    operation of law or in connection with any change of control. We may
                    assign or transfer these Terms, any or all of its rights under these
                    Terms, in whole or in part, without obtaining your consent or
                    approval, provided that you will be informed of such transfer in the
                    order provided herein.
                    <br />
                    <br />
                    20.5. Unless the context otherwise requires, a reference to one gender
                    shall include a reference to the other genders; words in the singular
                    shall include the plural and in the plural shall include the singular;
                    any words following the terms including, include, in particular, for
                    example or any similar expression shall be construed as illustrative
                    and shall not limit the sense of the words, description, definition,
                    phrase or term preceding those terms; Section headings do not affect
                    the interpretation of these Terms. These Terms shall not be construed
                    for or against us because of our role in drafting these Terms.
                    <br />
                    <br />
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
