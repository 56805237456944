import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from '../utils';
import { openLoader, closeLoader } from '../actions/loader.actions';

class ScrollToTop extends Component {
    static defaultProps = {
        stopLoading: () => {},
        startLoading: () => {},
        location: {},
        children: {},
        isLoader: true,
    };

    static propTypes = {
        stopLoading: PropTypes.func,
        startLoading: PropTypes.func,
        location: PropTypes.object,
        children: PropTypes.any,
        isLoader: PropTypes.bool,
    };

    componentDidMount() {
        this.hideLoadingScreen(2000);
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            startLoading,
        } = this.props;

        if (pathname !== prevProps.location.pathname) {
            startLoading();
            window.scrollTo(0, 0);
            this.hideLoadingScreen(400);
        }
    }

    hideLoadingScreen = time => {
        const { stopLoading } = this.props;

        setTimeout(() => {
            stopLoading();
        }, time);
    };

    render() {
        const { children, isLoader } = this.props;
        document.documentElement.style.overflowY = `${isLoader ? 'hidden' : 'visible'}`;
        return children;
    }
}

const mapStateToProps = state => {
    const {
        loader: { isLoader },
    } = state;

    return {
        isLoader,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        stopLoading: () => closeLoader(),
        startLoading: () => openLoader(),
    },
    dispatch,
);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(ScrollToTop);
