/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GITHUB_CONSTANTS } from '../../../../constants';
import listIcon from './images/list-icon.svg';
import style from './about.module.scss';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('howItWorks.about')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('aboutUs.howItWorks'),
                        }}
                    />
                    <div className={style.about__content}>
                        <p
                            className={style.about__content_text}
                            dangerouslySetInnerHTML={{
                                __html: t('texts.bugBountyText1'),
                            }}
                        />
                        <div className={style.about__contentBg}>
                            <p className={style.about__content_title}>
                                {t('texts.bugBountyText2')}
                            </p>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p className={style.about__content_text}>
                                    {t('texts.bugBountyText3')}{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={GITHUB_CONSTANTS}
                                    >
                                        {t('buttons.here')}
                                    </a>
                                </p>
                            </div>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p className={style.about__content_text}>
                                    {t('texts.bugBountyText4')}
                                </p>
                            </div>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p className={style.about__content_text}>
                                    {t('texts.bugBountyText5')}
                                </p>
                            </div>
                        </div>
                        <div className={style.about__contentBg}>
                            <p className={style.about__content_title}>
                                {t('texts.bugBountyText6')}
                            </p>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p className={style.about__content_text}>
                                    {t('texts.bugBountyText7')}{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={GITHUB_CONSTANTS}
                                    >
                                        {t('buttons.here')}
                                    </a>
                                </p>
                            </div>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p
                                    className={style.about__content_text}
                                    dangerouslySetInnerHTML={{
                                        __html: t('texts.bugBountyText8'),
                                    }}
                                />
                            </div>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p
                                    className={style.about__content_text}
                                    dangerouslySetInnerHTML={{
                                        __html: t('texts.bugBountyText9'),
                                    }}
                                />
                            </div>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p
                                    className={style.about__content_text}
                                    dangerouslySetInnerHTML={{
                                        __html: t('texts.bugBountyText10'),
                                    }}
                                />
                            </div>
                            <div className={style.about__list}>
                                <img src={listIcon} alt="listIcon" />
                                <p
                                    className={style.about__content_text}
                                    dangerouslySetInnerHTML={{
                                        __html: t('texts.bugBountyText11'),
                                    }}
                                />
                            </div>

                        </div>
                        <p
                            className={style.about__content_bottomText}
                            dangerouslySetInnerHTML={{
                                __html: t('texts.bugBountyText12'),
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
