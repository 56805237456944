import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Burger from 'react-css-burger';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArrowLeftIcon from '../../../assets/images/icons/arrow-left';
import { mobileSublinksActions } from '../../../../actions/mobile-sublinks.actions';
import ListOfLinks from '../header-links';
import SelectLangeage from '../../../language';
import { compose } from '../../../../utils';
import { openMenu, closeMenu } from '../../../../actions/drawer.actions';
import style from './mobile-menu.module.scss';

class MobileMenu extends Component {
    static defaultProps = {
        t: () => {},
        clearMobileLinks: () => {},
        closeDrawerMenu: () => {},
        openDrawerMenu: () => {},
        isOpen: false,
        location: {},
        mobileSublinks: '',
    };

    static propTypes = {
        t: PropTypes.func,
        clearMobileLinks: PropTypes.func,
        closeDrawerMenu: PropTypes.func,
        openDrawerMenu: PropTypes.func,
        isOpen: PropTypes.bool,
        location: PropTypes.object,
        mobileSublinks: PropTypes.string,
    };

    componentDidUpdate(prevProps) {
        const { closeDrawerMenu, location } = this.props;
        if (location !== prevProps.location) {
            closeDrawerMenu();
        }
    }

    componentWillUnmount() {
        const { closeDrawerMenu } = this.props;
        closeDrawerMenu();
    }

    toggleBurger = () => {
        const { isOpen, closeDrawerMenu, openDrawerMenu } = this.props;
        if (isOpen) {
            return closeDrawerMenu();
        }

        openDrawerMenu();
    };

    clearMobileLinks = () => {
        const { clearMobileLinks } = this.props;
        clearMobileLinks();
    }

    render() {
        const { t, isOpen, mobileSublinks } = this.props;

        const drawerStyle = isOpen ? style.drawer__opened : style.drawer__closed;

        if (isOpen) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'inherit';
        }

        const drawerHeight = {
            height: `${window.innerHeight}px`,
        };

        const burgerMenuStyle = isOpen ? 'burgerMenu burgerMenuActive' : 'burgerMenu';

        return (
            <Fragment>
                <div className={style.burgerMenu}>
                    <div className={burgerMenuStyle}>
                        <Burger
                            onClick={this.toggleBurger}
                            active={isOpen}
                            burger="spin"
                            color="white"
                            marginTop="0"
                            scale={0.65}
                        />
                    </div>
                </div>
                <div className={drawerStyle} style={drawerHeight}>
                    <SelectLangeage />
                    {mobileSublinks ? (
                        <div onClick={this.clearMobileLinks} className={style.back}>
                            <ArrowLeftIcon className={style.back__arrow} />
                            {t('back')}
                        </div>
                    ) : null}
                    <div
                        className={style.linksWrapper}
                        style={{ height: `${window.innerHeight}px` }}
                    >
                        <ListOfLinks
                            classNameList={style.header__links}
                            classNameItem={style.links_item}
                            classNameSubLinks={style.links_subLinks}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        mobileSublinks: { data: mobileSublinks },
        drawer: { isOpen },
    } = state;

    return {
        mobileSublinks,
        isOpen,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        clearMobileLinks: () => mobileSublinksActions.clear(),
        openDrawerMenu: () => openMenu(),
        closeDrawerMenu: () => closeMenu(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(MobileMenu);
