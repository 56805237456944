/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OrionWallet from './orion-page';
import { compose } from '../../../utils';
import notification from '../../../helpers/notifications';
import socialData from '../../../constants/social-data';
import { orionPath } from '../../../constants/path-location';
import background from './images/firstScreenBg.svg';
import icon1 from './images/1.svg';
import icon2 from './images/2.svg';
import icon3 from './images/3.svg';
import icon4 from './images/4.svg';
import icon5 from './images/5.svg';
import icon6 from './images/6.svg';
import style from './wallet-page.module.scss';

class WalletPage extends Component {
    static defaultProps = {
        t: () => {},
    };

    static propTypes = {
        t: PropTypes.func,
    };

    state = {};

    soonInMainnet = () => {
        const { t } = this.props;
        notification('', t('buttons.soonInMainnet'), 'success');
    };

    render() {
        const { t } = this.props;

        const achievements = [
            {
                img: icon1,
                title: t('texts.userFriendly'),
                text: t('texts.simpleAndEasy'),
            },
            {
                img: icon2,
                title: t('texts.secure'),
                text: t('texts.robustAndNonStandart'),
            },
            {
                img: icon3,
                title: t('texts.safe'),
                text: t('texts.privateKeyOnDevice'),
            },
            {
                img: icon4,
                title: t('texts.personal'),
                text: t('texts.turnYourDevice'),
            },
            {
                img: icon5,
                title: t('texts.functional'),
                text: t('texts.receiveSendAndStoreCoins'),
            },
            {
                img: icon6,
                title: t('texts.autonomous'),
                text: t('texts.walletCanOperate'),
            },
        ];

        return (
            <Fragment>
                <div className={style.firstScreen}>
                    <img
                        className={style.firstScreen__backgroundImg}
                        src={background}
                        alt="background"
                    />
                    <h1
                        className={style.firstScreen__title}
                        dangerouslySetInnerHTML={{ __html: t('headings.wallet') }}
                    />
                    <h3 className={style.firstScreen__subTitle}>
                        {t('texts.walletText')}
                    </h3>
                    <div className={style.firstScreen__bottom}>
                        <button
                            type="button"
                            onClick={this.soonInMainnet}
                            className={style.firstScreen__form_buttonFilled}
                        >
                            {t('buttons.soonInMainnet')}
                        </button>
                        <Link to={orionPath} className={style.firstScreen__form_button}>
                            {t('buttons.tryInOrion')}
                        </Link>
                    </div>
                    <div className={style.socialIcons}>
                        {socialData.map(item => {
                            const { img, link } = item;

                            return (
                                <a
                                    key={link}
                                    className={style.socialIcons__link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={link}
                                >
                                    {img}
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div className={style.container}>
                    <div className={style.container__wrapper}>
                        <h2 className={style.container__title}>
                            {t('howItWorks.about')}
                        </h2>
                        <div className={style.container__rightSide}>
                            <p
                                className={style.container__rightSide_title}
                                dangerouslySetInnerHTML={{
                                    __html: t('headings.BTCUWallet'),
                                }}
                            />
                            <div className={style.container__content}>
                                <p className={style.container__content_text}>
                                    {t('texts.walletDescription')}
                                </p>
                            </div>
                            <div className={style.achievements}>
                                {achievements.map(item => {
                                    const { img, title, text } = item;

                                    return (
                                        <div
                                            key={title}
                                            className={style.achievements__item}
                                        >
                                            <img
                                                className={style.achievements__item_img}
                                                src={img}
                                                alt="img"
                                            />
                                            <div>
                                                <p
                                                    className={
                                                        style.achievements__item_title
                                                    }
                                                >
                                                    {title}
                                                </p>
                                                <p
                                                    className={
                                                        style.achievements__item_text
                                                    }
                                                >
                                                    {text}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <OrionWallet title={t('headings.wallet')} path={orionPath} />
            </Fragment>
        );
    }
}

export default compose(withTranslation())(WalletPage);
