import { LOADER_CONSTANTS } from '../constants';

const loader = (state, action) => {
    if (state === undefined) {
        return {
            isLoader: true,
        };
    }

    switch (action.type) {
    case LOADER_CONSTANTS.OPEN_LOADER:
        return {
            isLoader: true,
        };

    case LOADER_CONSTANTS.CLOSE_LOADER:
        return {
            isLoader: false,
        };

    default:
        return state;
    }
};

export default loader;
