/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { bugBountyPath } from '../../../../constants';
import background from './images/background.svg';
import moreIcon from '../../../assets/images/icons/more_icon.svg';
import style from './how-it-work.module.scss';

const HowItWork = () => {
    const { t } = useTranslation();

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('howItWorks.about')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.bugBounty'),
                        }}
                    />
                    <div className={style.about__content}>
                        <p className={style.about__content_text}>
                            {t('texts.orionText2')}
                        </p>
                        <Link to={bugBountyPath} className={style.seeMore}>
                            <p>{t('buttons.learnMore')}</p>
                            <img src={moreIcon} alt="More" />
                        </Link>
                    </div>
                    <img
                        src={background}
                        alt="background"
                        className={style.about__background}
                    />
                </div>
            </div>
        </div>
    );
};

export default HowItWork;
