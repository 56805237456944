import React, { Fragment } from 'react';
import FirstScreen from './first-screen';
import About from './about';

const HowItWorks = () => (
    <Fragment>
        <FirstScreen />
        <About />
    </Fragment>
);

export default HowItWorks;
