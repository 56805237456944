/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import socialData from '../../../../constants/social-data';
import telegramIcon from './images/telegram-icon.svg';
import pathRedIcon from './images/path-red.svg';
import pathGreenIcon from './images/path-green.svg';
import style from './ultimatum.module.scss';

const Ultimatum = () => {
    const { t } = useTranslation();

    return (
        <div className={style.media}>
            <div className={style.media__wrapper}>
                <h2 className={style.media__title}>{t('aboutUs.howItWorksText')}</h2>
                <div className={style.media__rightSide}>
                    <p className={style.media__rightSide_title}>
                        Bitcoin <span>Ultimatum</span>
                    </p>
                    <div className={style.media__content}>
                        <p className={style.media__content_text}>
                            {t('texts.communityUltimatumText')}
                        </p>
                        <div className={style.media__telegram}>
                            <img
                                className={style.media__telegram_icon}
                                src={telegramIcon}
                                alt="telegramIcon"
                            />
                            <div>
                                <div className={style.media__telegram_item}>
                                    <img
                                        className={style.media__telegram_redPath}
                                        src={pathRedIcon}
                                        alt="pathRedIcon"
                                    />
                                    <div className={style.media__telegram_text}>
                                        <p>{t('texts.telegramChats1')}</p>
                                        <p>{t('texts.telegramChats2')}</p>
                                        <p>{t('texts.telegramChats3')}</p>
                                    </div>
                                </div>
                                <div className={style.media__telegram_item}>
                                    <img
                                        className={style.media__telegram_greenPath}
                                        src={pathGreenIcon}
                                        alt="pathRedIcon"
                                    />
                                    <div className={style.media__telegram_text}>
                                        <p>{t('texts.telegramChats4')}</p>
                                        <p>
                                            {t('texts.telegramChats5')}{' '}
                                            <a href="mailto:dev.chat@btcu.io">
                                            dev.chat@btcu.io
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.media__connectToUs}>
                            <p
                                className={style.media__connectToUs_text}
                                dangerouslySetInnerHTML={{
                                    __html: t('texts.connectToUs'),
                                }}
                            />
                        </div>
                        <div className={style.socialIcons}>
                            {socialData.map(item => {
                                const { img, link } = item;

                                return (
                                    <a
                                        key={link}
                                        className={style.socialIcons__link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={link}
                                    >
                                        {img}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ultimatum;
