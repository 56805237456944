import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { BackTop } from 'antd';
import ScrollToTop from '../../helpers/scroll-top';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import {
    HomePage,
    PageNotFound,
    LoadingPage,
    WalletPage,
    StorePage,
    ExplorerPage,
    WhoWeAre,
    SmartContracts,
    LeasingStaking,
    UltimatumPoS,
    PrivateTransactions,
    Orion,
    BugBounty,
    Airdrop,
    TermOfUse,
    PrivacyPolicy,
} from '../pages';
import Partnership from '../pages/partnership';
import Community from '../pages/community';
import DevelopersPage from '../pages/developers/developers-page';
import DevelopersView from '../pages/developers/developers-view';
import HowItWorks from '../pages/how-it-works';
import {
    walletPath,
    storePath,
    explorerPath,
    developersPath,
    developersIntroPath,
    whoWeArePath,
    howItWorksPath,
    partnershipPath,
    communityPath,
    smartContractsPath,
    leasingAndStakingPath,
    ultimatumPosPath,
    privateTransactionsPath,
    orionPath,
    bugBountyPath,
    airDropPath,
    termOfServicePath,
    privacyPolicyPath,
} from '../../constants';
import '../assets/styles/reset.scss';
import './app.scss';
import '../assets/styles/fonts.scss';
import 'react-notifications-component/dist/theme.css';

const App = () => (
    <Router>
        <ScrollToTop>
            <LoadingPage />
            <Header />
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path={walletPath} component={WalletPage} exact />
                <Route path={storePath} component={StorePage} exact />
                <Route path={explorerPath} component={ExplorerPage} exact />
                <Route path={whoWeArePath} component={WhoWeAre} exact />
                <Route path={howItWorksPath} component={HowItWorks} exact />
                <Route path={partnershipPath} component={Partnership} exact />
                <Route path={communityPath} component={Community} exact />
                <Route path={smartContractsPath} component={SmartContracts} exact />
                <Route path={leasingAndStakingPath} component={LeasingStaking} exact />
                <Route path={ultimatumPosPath} component={UltimatumPoS} exact />
                <Route path={privateTransactionsPath} component={PrivateTransactions} exact />
                <Route path={orionPath} component={Orion} exact />
                <Route path={bugBountyPath} component={BugBounty} exact />
                <Route path={airDropPath} component={Airdrop} exact />
                <Route path={developersIntroPath} component={DevelopersPage} exact />
                <Route path={developersPath} component={DevelopersView} />
                <Route path={termOfServicePath} component={TermOfUse} />
                <Route path={privacyPolicyPath} component={PrivacyPolicy} />
                <Route component={PageNotFound} />
            </Switch>
            <Footer />
            <ReactNotification />
            <BackTop />
        </ScrollToTop>
    </Router>
);

export default App;
