/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OrionWallet from '../wallet-page/orion-page';
import { compose } from '../../../utils';
import { orionPath, EXPLORER_MAINNET } from '../../../constants';
import socialData from '../../../constants/social-data';
import background from './images/firstScreenBg.svg';
import icon1 from './images/1.svg';
import icon2 from './images/2.svg';
import style from './explorer-page.module.scss';

class ExplorerPage extends Component {
    static defaultProps = {
        t: () => {},
    };

    static propTypes = {
        t: PropTypes.func,
    };

    state = {};

    render() {
        const { t } = this.props;

        const achievements = [
            {
                img: icon1,
                title: t('texts.stylishAndPractical'),
                text: t('texts.redesignedExplorer'),
            },
            {
                img: icon2,
                title: t('texts.compact'),
                text: t('texts.browserExplorer'),
            },
        ];

        return (
            <Fragment>
                <div className={style.firstScreen}>
                    <img
                        className={style.firstScreen__backgroundImg}
                        src={background}
                        alt="background"
                    />
                    <h1
                        className={style.firstScreen__title}
                        dangerouslySetInnerHTML={{ __html: t('headings.explorer') }}
                    />
                    <h3 className={style.firstScreen__subTitle}>
                        {t('texts.explorerText')}
                    </h3>
                    <div className={style.firstScreen__bottom}>
                        <a
                            href={EXPLORER_MAINNET}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={style.firstScreen__form_buttonFilled}
                        >
                            Mainnet
                        </a>
                        <Link to={orionPath} className={style.firstScreen__form_button}>
                            {t('buttons.tryInOrion')}
                        </Link>
                    </div>
                    <div className={style.socialIcons}>
                        {socialData.map(item => {
                            const { img, link } = item;

                            return (
                                <a
                                    key={link}
                                    className={style.socialIcons__link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={link}
                                >
                                    {img}
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div className={style.container}>
                    <div className={style.container__wrapper}>
                        <h2 className={style.container__title}>
                            {t('howItWorks.about')}
                        </h2>
                        <div className={style.container__rightSide}>
                            <p
                                className={style.container__rightSide_title}
                                dangerouslySetInnerHTML={{
                                    __html: t('headings.BTCUExplorer'),
                                }}
                            />
                            <div className={style.container__content}>
                                <p className={style.container__content_text}>
                                    {t('texts.BTCUExplorerText')}
                                </p>
                            </div>
                            <div className={style.achievements}>
                                {achievements.map(item => {
                                    const { img, title, text } = item;

                                    return (
                                        <div
                                            key={title}
                                            className={style.achievements__item}
                                        >
                                            <img
                                                className={style.achievements__item_img}
                                                src={img}
                                                alt="img"
                                            />
                                            <div>
                                                <p
                                                    className={
                                                        style.achievements__item_title
                                                    }
                                                >
                                                    {title}
                                                </p>
                                                <p
                                                    className={
                                                        style.achievements__item_text
                                                    }
                                                >
                                                    {text}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <OrionWallet title={t('headings.explorer')} path={orionPath} />
            </Fragment>
        );
    }
}

export default compose(withTranslation())(ExplorerPage);
