/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './privacy-policy.module.scss';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className={style.privacyPolicy}>
            <h1 className={style.privacyPolicy__mainTitle}>
                BITCOIN ULTIMATUM <br /> <br />
                {t('headings.privacyPolicy')}
            </h1>
            <p className={style.privacyPolicy__date}>Last Updated: September 2, 2021</p>
            <div className={style.privacyPolicy__text}>
                <p>
                    The website at
                    <a href="https://btcu.io/" target="_blank" rel="noopener noreferrer">
                        https://btcu.io
                    </a>
                    (the “Website”), BTCU explorer available at{' '}
                    <a
                        href="https://explorer.btcu.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://explorer.btcu.io
                    </a>
                    (the “Explorer”), and BTCU wallet, including the web-based application
                    available at
                    <a
                        href="https://wallet.btcu.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://wallet.btcu.io
                    </a>
                    , browser extension (plugin), desktop and mobile applications (the
                    “Wallet”) are provided by Profit Foundation LTD (“we”). The Wallet,
                    Explorer, and Wallet hereinafter collectively referred to as the
                    “Products”.
                </p>
                <br />
                <p>
                    This Privacy Policy tells you how your personal information is being
                    processed when you access and use the Products. We will process your
                    personal data in accordance with this Privacy Policy and in compliance
                    with the applicable data protection legislation (the “Applicable
                    Legislation”).
                </p>
                <br />
                <p>
                    Regarding personal data we collected from you or you provided us with,
                    we are acting as a data controller.
                </p>
                <br />
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>1. CONTACT DETAILS</h4>
                <p className={style.privacyPolicy__content}>
                    Company name: Profit Foundation LTD
                    <br />
                    <br />
                    Address: 73 Upper Paya Lebar Road #06-01C Centro Bianco, Singapore,
                    534818
                    <br />
                    <br />
                    Contact email: support@btcu.io <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    2. HOW DO WE COLLECT AND PROCESS YOUR PERSONAL DATA
                </h4>
                <p className={style.privacyPolicy__content}>
                    2.1. WEBSITE
                    <br />
                    <br />
                    (A) Subscription
                    <br />
                    <br />
                    If you subscribe to our newsletter, we will collect your email address
                    to send you news related to the BTCU project, its new technologies and
                    updates.
                    <br />
                    <br />
                    The legal basis for processing your email address and sending you
                    respective letters is your consent. <br />
                    <br />
                    You may withdraw your consent at any time by clicking the unsubscribe
                    button on the bottom of every marketing email that we send to you. If
                    you unsubscribe from receiving the letters from us, we will delete
                    your email from our respective database.
                    <br />
                    <br />
                    (B) Communication
                    <br />
                    <br />
                    If you contact us via the details indicated within the Products, we
                    may collect certain personal information that is included in such
                    communication. Such information may include your name and/or email
                    address. Such data will be processed based on our legitimate interest
                    to assist you with your request.
                    <br />
                    <br />
                    (C) Automatically Collected Data
                    <br />
                    <br />
                    Your personal data is automatically collected and processed via Google
                    Analytics tool when you access or use the Website.
                    <br />
                    <br />
                    Google Analytics provides us with the aggregated information regarding
                    how many users visited the Website, how long they stayed on the
                    Website, etc. Google Analytics gathers information about Website use
                    by means of cookies. Cookies are a feature of the web browser software
                    that allows web servers to recognise the device used to access the
                    Website. A cookie is a small text file that the Website saves on your
                    computer or mobile device when you visit the Website. They allow the
                    Website to remember your actions and preferences over a period of time
                    to improve products and services.
                    <br />
                    <br />
                    The information gathered by Google Analytics includes the following:
                    (1) IP address, (2) the type of device used, (3) the device’s
                    operating system, (4) the browser used. After collecting the personal
                    data, Google Analytics creates reports about the use of the Website,
                    which contain the aggregated information where we do not see any data
                    pertaining to a particular person. In other words, we cannot identify
                    you from the other visitors. Please note that according to Google
                    Analytics documentation, the IP address is anonymised (masked), so
                    neither we nor Google can identify the IP address of a particular
                    visitor.
                    <br />
                    <br />
                    In addition to the above, with Google Analytics we collect certain
                    information regarding the use of the Website, for instance, when you
                    clicked a certain button or made some input. This information is also
                    aggregated and we cannot identify your actions from the actions of
                    other visitors.
                    <br />
                    <br />
                    To our knowledge, the data collected via Google Analytics, with the
                    exception of the IP address, is not anonymised and will be transmitted
                    to, processed and stored by Google in the United States. You can learn
                    more about how Google processes personal data in Google’s privacy
                    policy. Note that competent US state authorities may have access to
                    the personal data collected via Google Analytics. In addition, Google
                    may use this personal data for any of its own purposes, such as
                    profiling and cross-platform tracking. You can learn more about Google
                    Analytics, its purposes and functions here.
                    <br />
                    <br />
                    We will always ask your consent to place and use cookies from Google
                    Analytics. When you visit the Website, you are able to opt-out from
                    using cookies. You also may object to the collection of personal data
                    by Google Analytics from the Website by downloading and installing a
                    browser add-on from Google.
                    <br />
                    <br />
                    2.2. EXPLORER
                    <br />
                    <br />
                    (A) Account Registration
                    <br />
                    <br />
                    Within the Explorer you are able to register an account, in which case
                    we may collect and process the following data:
                    <br />
                    <br />
                    - email address (mandatory); <br />
                    <br />
                    - password (mandatory); <br />
                    <br />
                    - login (mandatory); <br />
                    <br />
                    - photo (optional). <br />
                    <br />
                    Such data is processed based on our legitimate interest to provide you
                    with the functionality of the account. Such functionality may be
                    updated from time to time and may not be available for the users which
                    do not have an account. Please kindly note that you may not provide
                    any personal data when registering an account.
                    <br />
                    <br />
                    (B) Automatically Collected Data
                    <br />
                    <br />
                    Within the Explorer certain personal data is collected and processed
                    automatically in the same way as described in paragraph 2.1(C) above.
                    <br />
                    <br />
                    2.3. WALLET
                    <br />
                    <br />
                    (A) Wallet Account
                    <br />
                    <br />
                    The Wallet is a decentralised, non-custodial digital wallet and we do
                    not collect, process, or store any personal information related to
                    your use of the Wallet. Moreover, you do not have an opportunity to
                    provide your personal data to us through the Wallet. When you use the
                    Wallet, we are not able and we will not identify you as an individual.{' '}
                    <br />
                    <br />
                    Your password, private key, and mnemonic phrase are processed locally
                    on your device and are not processed on our servers. <br />
                    <br />
                    When you register a Wallet account, you are assigned with a Wallet
                    address, which is a unique identifier within the blockchain networks.
                    Such Wallet address and any transactions related thereto are publicly
                    available and may be viewed by any user of the respective blockchain
                    network. In certain circumstances your Wallet address may reveal your
                    identity and, thus, under the Applicable Legislation, be considered
                    personal information. Since the information regarding your Wallet
                    address and any transactions related thereto are available on
                    decentralised blockchain networks, we neither control nor manage such
                    data and any blockchain user may view such data. The data available on
                    a decentralised blockchain cannot be altered or deleted. Therefore,
                    you should consider the above features of data processing within
                    decentralised blockchain networks before registering a Wallet account
                    or using the Wallet. <br />
                    <br />
                    (B) Automatically Collected Data
                    <br />
                    <br />
                    Within the Wallet certain personal data is collected and processed
                    automatically in the same way as described in paragraph 2.1(C) above.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    3. HOW DO WE SHARE YOUR PERSONAL DATA
                </h4>
                <p className={style.privacyPolicy__content}>
                    We do not sell or rent out your personal data. However, we may share
                    your personal data in accordance with this Privacy Policy, Applicable
                    Legislation, or with your consent.
                    <br />
                    <br />
                    We may share your data with the following categories of recipients:
                    <br />
                    <br />
                    (1) our affiliates, meaning the companies that we control, are
                    controlled by, or under common control with us;
                    <br />
                    <br />
                    (2) web hosting services used to set up servers;
                    <br />
                    <br />
                    (3) our suppliers and contractors, including technical specialists,
                    where necessary for maintaining security and stability of the
                    Products;
                    <br />
                    <br />
                    (4) your email service provider, when we authenticate your email
                    address and send you service-related notifications or newsletters;
                    <br />
                    <br />
                    (5) Google Analytics, when we collect data via their cookies.
                    <br />
                    <br />
                    Your personal data may also be disclosed if (1) we sell or transfer
                    the Products, in which case we may disclose your personal data to the
                    buyer or transferee, or (2) we are under a duty to disclose or share
                    your personal data in order to comply with the Applicable Legislation.
                    <br />
                    <br />
                    Please note that if we share any portion of your personal data with
                    third parties, we will do our best to secure such transfer using
                    appropriate legal, organisational, and technical measures. <br />
                    <br />
                    Please note that sometimes we may transfer your personal data to
                    countries that do not offer the same level of data protection as the
                    laws of your country (such as, for example, the data protection
                    legislation of Switzerland, the EU, EEA, or UK).
                    <br />
                    <br />
                    You may reach us at the contact details indicated above to ask whether
                    your personal data is subject to transfer to a third country.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>4. SOCIAL NETWORKS</h4>
                <p className={style.privacyPolicy__content}>
                    The Products may contain social networks plug-ins such as Telegram,
                    Instagram, Facebook, Medium, LinkedIn, Twitter. When you click on
                    them, the respective social network may record that you were
                    redirected from the Products, and any such processing of your personal
                    data is subject to the privacy rules of the respective social network.
                    We are not responsible for data collected by these social networks.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    5. SECURING YOUR PERSONAL DATA
                </h4>
                <p className={style.privacyPolicy__content}>
                    We do our best to keep your data secure. We always review and update
                    appropriate technical and organisational measures to keep your
                    personal data secure, in accordance with our internal policies and
                    procedures regarding the storage of, access to and disclosure of
                    personal data. <br />
                    <br />
                    Your personal information may undergo anonymisation, pseudonymisation,
                    and/or encryption to ensure safe transfer and/or processing.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    6. HOW LONG DO WE STORE YOUR PERSONAL DATA
                </h4>
                <p className={style.privacyPolicy__content}>
                    We store your personal data as long as it is necessary for the purpose
                    of data processing. When the processing of your personal data is no
                    longer necessary, subject to this Privacy Policy and the requirements
                    of the Applicable Legislation, we will either delete or anonymise such
                    personal data.
                    <br />
                    <br />
                    Cookies are stored on your browsing device during the period of their
                    validity, which is indicated in your browser, or until they are
                    deleted.
                    <br />
                    <br />
                    If the processing of certain personal data is based on your consent
                    (for example, email address for receiving newsletters) and you have
                    withdrawn it, we will stop processing of such data if we are not
                    otherwise legally permitted or required to keep these data.
                    <br />
                    <br />
                    Please note that we may further process your personal data if it is
                    required by the Applicable Legislation or is necessary to for the
                    purposes of defending or making legal claims until the end of the
                    period required by the relevant law or until the settlement of any
                    such claims, as applicable.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    7. YOUR DATA PROTECTION RIGHTS
                </h4>
                <p className={style.privacyPolicy__content}>
                    Subject to certain limitations under the Applicable Legislation, you
                    may have the following rights: <br />
                    <br />
                    (1) right to withdraw your consent (if we process your personal data
                    on the basis of your consent);
                    <br />
                    <br />
                    (2) right to access to your personal data (i.e., the right to receive
                    certain information regarding your personal data processing and a copy
                    of your personal data that undergoes processing);
                    <br />
                    <br />
                    (3) right to correct your incorrect data and complete your incomplete
                    data;
                    <br />
                    <br />
                    (4) right to erase your personal data;
                    <br />
                    <br />
                    (5) right to restrict use of your personal data;
                    <br />
                    <br />
                    (6) right to data portability (if we process your data on the basis of
                    your consent or performance of a contract with you);
                    <br />
                    <br />
                    (7) right to submit a complaint with a supervisory authority.
                    <br />
                    <br />
                    You also have the right to object to the processing of your personal
                    data for the direct marketing purposes, as well as the right to object
                    to the processing based on our legitimate interest.
                    <br />
                    <br />
                    We do not make any automated decision-making regarding any of your
                    personal data.
                    <br />
                    <br />
                    To exercise your rights please contact us at the contact details
                    indicated above with the description of your request. Please note that
                    in this case we may request you to provide certain proofs to verify
                    your identity.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    8. CHILDREN’S PERSONAL DATA
                </h4>
                <p className={style.privacyPolicy__content}>
                    The Products are not intended to be used or accessed by children. We
                    do not knowingly process any personal data of children, i.e., persons
                    below 18 years old. In the case we realise that we process personal
                    data of a child, we promptly erase such personal data.
                    <br />
                    <br />
                </p>
            </div>
            <div className={style.privacyPolicy__wrapper}>
                <h4 className={style.privacyPolicy__title}>
                    9. CHANGES TO THIS PRIVACY POLICY
                </h4>
                <p className={style.privacyPolicy__content}>
                    We may unilaterally make changes to this Privacy Policy at any time,
                    in its sole discretion. In such case, we will update the “Last
                    Updated” date above. Such changes will take effect as soon as they are
                    posed unless otherwise is decided by us. Please check back frequently
                    to see any updates or changes to this Privacy Policy.
                    <br />
                    <br />
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
