
import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { currentLocale } from './current-locale.reducer';
import drawer from './drawer.reducer';
import loader from './loader.reducer';
import mobileSublinks from './mobile-links.reducer';
import subscribeNews from './post-subscribe-news.reducer';

const rootReducer = combineReducers({
    alert,
    currentLocale,
    drawer,
    loader,
    mobileSublinks,
    subscribeNews,
});

export default rootReducer;
