/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import coinsbitIcon from '../../../assets/images/logos/white/coinsbit.svg';
import profItIcon from '../../../assets/images/logos/white/prof-it.svg';
import pmIcon from '../../../assets/images/logos/white/pm.svg';
import openBusiness from '../../../assets/images/logos/white/openBusiness.svg';
import intelligent from '../../../assets/images/logos/white/intelligent.svg';
import citiesabc from '../../../assets/images/logos/white/citiesabc.svg';
import fashionabc from '../../../assets/images/logos/white/fashionabc.svg';
import investorMedia from '../../../assets/images/logos/white/investorMedia.svg';
import hedgethink from '../../../assets/images/logos/white/hedgethink.svg';
import tradersdna from '../../../assets/images/logos/white/tradersdna.svg';
import unic from '../../../assets/images/logos/white/unic.png';
import atomicWallet from '../../../assets/images/logos/white/atomic-wallet.svg';
import chainSecure from '../../../assets/images/logos/white/chain-secure.svg';
import zUnionIcon from '../../../assets/images/logos/white/z-union.png';
import indianInstituteIcon from '../../../assets/images/logos/white/indian-institute.svg';
import hackcontrolIcon from '../../../assets/images/logos/white/hackcontrol.svg';
import BTCNEXTIcon from '../../../assets/images/logos/white/BTCNEXT.svg';
import platinumIcon from '../../../assets/images/logos/white/platinum.svg';
import blockchainisrael from '../../../assets/images/logos/white/blockchainisrael.png';
import style from './join-us.module.scss';

const JoinUs = () => {
    const { t } = useTranslation();

    const data = [
        {
            label: t('headings.partners'),
            title: t('partnership.joinToUs'),
        },
    ];

    const partners = [
        {
            link: 'https://www.unic.ac.cy/iff/dlrc/',
            img: unic,
        },
        {
            link: 'https://indianblockchaininstitute.com',
            img: indianInstituteIcon,
        },
        {
            link: 'https://z-union.ru/en/',
            img: zUnionIcon,
        },
        {
            link: 'https://coinsbit.io/',
            img: coinsbitIcon,
        },
        {
            link: 'https://prof-it.bz/',
            img: profItIcon,
        },
        {
            link: 'https://prmr.com/',
            img: pmIcon,
        },
        {
            link: 'https://blockchainisrael.io',
            img: blockchainisrael,
        },
        {
            link: 'https://hackcontrol.org/',
            img: hackcontrolIcon,
        },
        {
            link: 'https://www.openbusinesscouncil.org',
            img: openBusiness,
        },
        {
            link: 'https://www.intelligenthq.com',
            img: intelligent,
        },
        {
            link: 'https://www.btcnext.io/',
            img: BTCNEXTIcon,
        },
        {
            link: 'https://platinum.fund/',
            img: platinumIcon,
        },
        {
            link: 'https://www.citiesabc.com',
            img: citiesabc,
        },
        {
            link: 'https://www.fashionabc.org ',
            img: fashionabc,
        },
        {
            link: 'https://www.tradersdna.com',
            img: tradersdna,
        },
        {
            link: 'https://www.hedgethink.com',
            img: hedgethink,
        },
        {
            link: 'https://www.investor-media.com/',
            img: investorMedia,
        },
        {
            link: 'https://atomicwallet.io',
            img: atomicWallet,
        },
        {
            link: 'https://chain-secure.com/',
            img: chainSecure,
        },
    ];

    return (
        <div className={style.joinUs}>
            {data.map(item => {
                const { label, title } = item;

                return (
                    <div key={title} className={style.joinUs__wrapper}>
                        <h2 className={style.joinUs__title}>{label}</h2>
                        <div className={style.joinUs__rightSide}>
                            <p
                                className={style.joinUs__rightSide_title}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                            <div className={style.partners__content}>
                                {partners.map(items => {
                                    const { link, img } = items;

                                    return (
                                        <a
                                            className={style.partners__content_link}
                                            key={link}
                                            href={link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={img} alt="logo" />
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default JoinUs;
