/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import socialData from '../../../../constants/social-data';
import background from './images/firstScreenBg.svg';
import style from './first-screen.module.scss';

const FirstScreen = () => {
    const { t } = useTranslation();

    return (
        <div className={style.firstScreen}>
            <img
                className={style.firstScreen__backgroundImg}
                src={background}
                alt="background"
            />
            <h1 className={style.firstScreen__title}>
                Ultimatum
                <br /> PoS
            </h1>
            <h3 className={style.firstScreen__subTitle}>
                {t('texts.ultimatumPosShort')}
            </h3>
            <a
                href="https://t.me/BTCUgroup"
                className={style.firstScreen__button}
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('buttons.joinUs')}
            </a>
            <div className={style.socialIcons}>
                {socialData.map(item => {
                    const { img, link } = item;

                    return (
                        <a
                            key={link}
                            className={style.socialIcons__link}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link}
                        >
                            {img}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default FirstScreen;
