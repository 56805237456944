/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
// import mykolaUdianskyi from '../../assets/images/team/Mykola_Udianskyi.jpg';
// import ericMa from '../../assets/images/team/eric_Ma.jpg';
import bogdanPrilepa from '../../assets/images/team/bogdan_prilepa.jpg';
import andriiSaranenko from '../../assets/images/team/andrii_saranenko.jpg';
import julietSu from '../../assets/images/team/juliet_Su.jpg';
import dinisGuarda from '../../assets/images/team/dinis_Guarda.jpg';
import vadimYarmak from '../../assets/images/team/vadim_Yarmak.jpg';
import andreiKurkin from '../../assets/images/team/Andrei_Kurkin.jpg';
import linkedInIcon from '../../assets/images/icons/linkedIn-icon.svg';
import facebookIcon from '../../assets/images/icons/facebook_icon.svg';
import moreIcon from '../../assets/images/icons/more_icon.svg';
import hideIcon from '../../assets/images/icons/hide-icon.svg';

const TeamLayout = ({
    contentStyles,
    photoActiveStyles,
    photoStyles,
    descriptionWrStyles,
    itemStyles,
    itemBlurStyles,
    descriptionActiveStyles,
    descriptionStyles,
    socialsStyles,
    linkedInStyles,
    facebookStyles,
    seeMoreStyles,
    positionStyles,
    nameStyles,
    containerStyles,
    wrapperActiveStyles,
    wrapperStyles,
}) => {
    const { t } = useTranslation();
    const [activeSeeMore, setSeeMore] = useState('');

    const expand = name => {
        setSeeMore(name);
    };

    const collapse = () => {
        setSeeMore('');
    };

    const data = [
        // {
        //     name: 'Eric Ma',
        //     img: ericMa,
        //     linkedIn: 'https://www.linkedin.com/in/eric-ma-2536ab217/',
        //     facebook: '',
        //     description: t('ambassadors.ericMa'),
        //     position: 'Chief Executive Officer',
        // },
        // {
        //     name: 'Nikolai Udianskiy',
        //     img: mykolaUdianskyi,
        //     linkedIn: '',
        //     facebook: 'https://www.facebook.com/nikolay.aleksandrovich.311',
        //     description: t('team.mykolaUdianskyi'),
        //     position: 'Co-founder',
        // },
        {
            name: 'Bohdan Prylepa',
            img: bogdanPrilepa,
            linkedIn: 'https://www.linkedin.com/in/prilepab/',
            facebook: '',
            description: t('team.bogdanPrilepa'),
            position: 'Chief Operating Officer',
        },
        {
            name: 'Dinis Guarda',
            img: dinisGuarda,
            linkedIn: 'https://www.linkedin.com/in/dinisguarda/',
            facebook: '',
            description: t('team.dinisGuarda'),
            position: 'Team Advisor Strategy and Development',
        },
        {
            name: 'Juliet  Su',
            img: julietSu,
            linkedIn: 'https://www.linkedin.com/in/juliet-su-苏丽-438b3725',
            facebook: '',
            description: t('team.julietSu'),
            position: 'Chief Strategic Officer',
        },
        {
            name: 'Andrei Kurkin',
            img: andreiKurkin,
            linkedIn: 'https://www.linkedin.com/in/andrei-kurkin-19856273',
            facebook: '',
            description: t('ambassadors.andreiKurkin'),
            position: 'Business Development Officer',
        },
        {
            name: 'Andriy Saranenko',
            img: andriiSaranenko,
            linkedIn: 'https://www.linkedin.com/in/andriy-s-a1ba9912a/',
            facebook: '',
            description: t('team.andriiSaranenko'),
            position: 'Chief Technology Officer',
        },
        {
            name: 'Vadim Yarmak',
            img: vadimYarmak,
            linkedIn: 'https://www.linkedin.com/in/vadim-yarmak-5a0492117/',
            facebook: '',
            description: t('team.vadimYarmak'),
            position: 'Chief Marketing Officer',
        },
    ];

    const contentLength = i18n.language === 'cn' ? 130 : 170;

    return (
        <div className={contentStyles}>
            {data.map(item => {
                const {
                    name, img, linkedIn, facebook, description, position,
                } = item;

                const isActive = activeSeeMore === name;

                const photoStyle = isActive ? photoActiveStyles : photoStyles;

                const descriptionWrStyle = isActive ? descriptionWrStyles : '';

                let itemstyle = itemStyles;

                if (activeSeeMore && !isActive && window.innerWidth > 499) {
                    itemstyle = classNames(itemStyles, itemBlurStyles);
                }

                let descroptionStyle = descriptionActiveStyles;
                let descriptionText = description;

                if (description.length > contentLength && !isActive) {
                    descroptionStyle = descriptionStyles;
                }

                if (description.length > contentLength) {
                    descriptionText = `${description.slice(0, contentLength)}...`;
                }

                if (isActive) {
                    descriptionText = description;
                }

                const socials = (
                    <div className={socialsStyles}>
                        {linkedIn ? (
                            <a
                                href={linkedIn}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={linkedInStyles}
                            >
                                <img src={linkedInIcon} alt="linkedIn" />
                            </a>
                        ) : null}

                        {facebook ? (
                            <a
                                href={facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={facebookStyles}
                            >
                                <img src={facebookIcon} alt="facebook" />
                            </a>
                        ) : null}
                    </div>
                );

                const seemore = (
                    <div style={{ cursor: 'pointer' }}>
                        {isActive ? (
                            <div className={seeMoreStyles} onClick={collapse}>
                                <p>{t('buttons.hide')}</p>
                                <img src={hideIcon} alt="hide" />
                            </div>
                        ) : (
                            <div className={seeMoreStyles} onClick={() => expand(name)}>
                                <p>{t('buttons.seeMore')}</p>
                                <img src={moreIcon} alt="More" />
                            </div>
                        )}
                    </div>
                );

                const cardStyle = isActive
                    ? classNames(wrapperActiveStyles, wrapperStyles)
                    : wrapperStyles;

                return (
                    <div key={name} className={cardStyle}>
                        <div className={itemstyle} tabIndex="0" onBlur={collapse}>
                            <div>
                                <img src={img} alt="logo" className={photoStyle} />
                                <p className={positionStyles}>{position}</p>
                            </div>
                            <p className={nameStyles}>{name}</p>
                            {socials}
                            <div className={containerStyles}>
                                <div className={descriptionWrStyle}>
                                    <p
                                        className={descroptionStyle}
                                        dangerouslySetInnerHTML={{
                                            __html: descriptionText,
                                        }}
                                    />
                                    {description.length > contentLength ? seemore : null}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

TeamLayout.defaultProps = {
    contentStyles: '',
    photoActiveStyles: '',
    photoStyles: '',
    descriptionWrStyles: '',
    itemStyles: '',
    itemBlurStyles: '',
    descriptionActiveStyles: '',
    descriptionStyles: '',
    socialsStyles: '',
    linkedInStyles: '',
    facebookStyles: '',
    seeMoreStyles: '',
    positionStyles: '',
    nameStyles: '',
    containerStyles: '',
    wrapperActiveStyles: '',
    wrapperStyles: '',
};

TeamLayout.propTypes = {
    contentStyles: PropTypes.string,
    photoActiveStyles: PropTypes.string,
    photoStyles: PropTypes.string,
    descriptionWrStyles: PropTypes.string,
    itemStyles: PropTypes.string,
    itemBlurStyles: PropTypes.string,
    descriptionActiveStyles: PropTypes.string,
    descriptionStyles: PropTypes.string,
    socialsStyles: PropTypes.string,
    linkedInStyles: PropTypes.string,
    facebookStyles: PropTypes.string,
    seeMoreStyles: PropTypes.string,
    positionStyles: PropTypes.string,
    nameStyles: PropTypes.string,
    containerStyles: PropTypes.string,
    wrapperActiveStyles: PropTypes.string,
    wrapperStyles: PropTypes.string,
};

export default TeamLayout;
