import React from 'react';
import PropTypes from 'prop-types';

const Twitter = ({ className }) => (
    <svg
        className={className}
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.84992 3.34039L6.87615 3.75904L6.43905 3.70778C4.848 3.51127 3.45802 2.84486 2.27784 1.72562L1.70087 1.17028L1.55225 1.58038C1.23754 2.49456 1.43861 3.46001 2.09426 4.10933C2.44394 4.46817 2.36526 4.51943 1.76206 4.30584C1.55225 4.23749 1.36867 4.18623 1.35119 4.21186C1.28999 4.27166 1.4998 5.04915 1.6659 5.35672C1.89319 5.78391 2.35652 6.20256 2.86356 6.45033L3.29192 6.64683L2.78488 6.65538C2.29533 6.65538 2.27784 6.66392 2.33029 6.84334C2.50514 7.39868 3.19576 7.9882 3.96505 8.24452L4.50706 8.42393L4.03499 8.69734C3.33563 9.09035 2.51388 9.31249 1.69213 9.32957C1.29874 9.33812 0.975281 9.37229 0.975281 9.39792C0.975281 9.48336 2.04181 9.96181 2.66249 10.1498C4.52454 10.7051 6.73628 10.4659 8.39726 9.51754C9.57743 8.84258 10.7576 7.50121 11.3084 6.20256C11.6056 5.51051 11.9028 4.24603 11.9028 3.63943C11.9028 3.24641 11.929 3.19515 12.4186 2.72524C12.7071 2.45184 12.9781 2.15281 13.0305 2.06737C13.118 1.90504 13.1092 1.90504 12.6634 2.05028C11.9203 2.3066 11.8154 2.27242 12.1826 1.88795C12.4536 1.61455 12.777 1.11901 12.777 0.97377C12.777 0.948139 12.6459 0.990858 12.4973 1.06775C12.3399 1.15319 11.9902 1.28135 11.728 1.35824L11.2559 1.50348L10.8275 1.22154C10.5915 1.06775 10.2593 0.896876 10.0845 0.845614C9.63863 0.726001 8.95675 0.743089 8.55462 0.879789C7.46186 1.26426 6.77124 2.25534 6.84992 3.34039Z"
            fill="white"
        />
    </svg>
);

Twitter.defaultProps = {
    className: '',
};

Twitter.propTypes = {
    className: PropTypes.string,
};

export default Twitter;
