import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import notification from '../../../../helpers/notifications';
import appleIcon from '../../../assets/images/icons/apple-icon.svg';
import windowsIcon from '../../../assets/images/icons/windows-icon.svg';
import linux64Icon from '../../../assets/images/icons/linux-64-icon.svg';
import style from './wallet.module.scss';

const Wallet = () => {
    const { t } = useTranslation();

    const data = [
        {
            id: '1',
            icon: appleIcon,
            link: '/',
            title: 'Mac',
            isDummy: true,
        },
        {
            id: '2',
            icon: windowsIcon,
            link: 'https://btcu.io/OrionInstallWin.exe',
            title: 'Windows',
            isDummy: false,
        },
        {
            id: '4',
            icon: linux64Icon,
            link: 'https://btcu.io/OrionUbuntu_x64.zip',
            title: 'Linux x64',
            isDummy: false,
        },
    ];

    const comingSoon = () => {
        notification('', t('buttons.comingSoon'), 'success');
    };

    return (
        <div className={style.wallet} id="wallet">
            <h2 className={style.wallet__title}>{t('navigation.tryIt')}</h2>
            <div className={style.wallet__rightSide}>
                <p className={style.wallet__rightSide_title}>
                    Orion <span>{t('headings.wallet')}</span>
                </p>
                <div className={style.wallet__bottom}>
                    {data.map(item => {
                        const {
                            id, icon, title, link, isDummy,
                        } = item;

                        return (
                            <Fragment>
                                {isDummy ? (
                                    <div
                                        key={id}
                                        onClick={comingSoon}
                                        className={style.wallet__bottom_link}
                                    >
                                        <img src={icon} alt="icon" />
                                        <span>{title}</span>
                                    </div>
                                ) : (
                                    <a
                                        key={id}
                                        href={link}
                                        download
                                        className={style.wallet__bottom_link}
                                    >
                                        <img src={icon} alt="icon" />
                                        <span>{title}</span>
                                    </a>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Wallet;
