import React from 'react';
import Facebook from '../components/assets/images/icons/facebook';
import Instagram from '../components/assets/images/icons/instagram';
import Telegram from '../components/assets/images/icons/telegram';
import Twitter from '../components/assets/images/icons/twitter';
import LinkedIn from '../components/assets/images/icons/linkedIn';
import Medium from '../components/assets/images/icons/medium';

const socialData = [
    {
        img: <Facebook />,
        link: 'https://www.facebook.com/bitcoinultimatum/',
    },
    {
        img: <Instagram />,
        link: 'https://www.instagram.com/btcu.io/?igshid=iuv430l5fxu9',
    },
    {
        img: <Twitter />,
        link: 'https://twitter.com/btcu_io',
    },
    {
        img: <Telegram />,
        link: 'https://t.me/BitcoinUltimatum',
    },
    {
        img: <LinkedIn />,
        link: 'https://www.linkedin.com/company/bitcoin-ultimatum/',
    },
    {
        img: <Medium />,
        link: 'https://bitcoinultimatum.medium.com/',
    },
];

export default socialData;
