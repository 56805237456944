import React, { Fragment } from 'react';
import FirstScreen from './first-screen';
import MainIdea from './main-idea';
import HowStart from './how-start';
import ForExchangesUsers from './for-exchanges-users';


const Airdrop = () => (
    <Fragment>
        <FirstScreen />
        <MainIdea />
        <HowStart />
        <ForExchangesUsers />
    </Fragment>
);

export default Airdrop;
