import React, { Fragment } from 'react';
import FirstScreen from './first-screen';
import About from './about';
import HowItWork from './how-it-work';
import Explorer from './explorer';
import Wallet from './wallet';

const Orion = () => (
    <Fragment>
        <FirstScreen />
        <About />
        <HowItWork />
        <Explorer />
        <Wallet />
    </Fragment>
);

export default Orion;
