/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { GITHUB_CONSTANTS } from '../../../../constants';
import { compose } from '../../../../utils';
import githubIcon from '../../../assets/images/icons/github.svg';
import style from './docs.module.scss';

const GITHUB_API_URL = 'https://api.github.com/repos/bitcoin-ultimatum/orion/';

class DocsPage extends Component {
    static defaultProps = {
        t: () => {},
    };

    static propTypes = {
        t: PropTypes.func,
    };

    state = {
        gitHub: {},
    };

    componentDidMount() {
        this.loadGithubData();
    }

    loadGithubData = async () => {
        const response = await fetch(`${GITHUB_API_URL}commits?page=1&per_page=1`);
        const gitHub = await response.json();
        this.setState({
            gitHub: gitHub[0],
        });
    };

    render() {
        const { t } = this.props;
        const {
            gitHub: {
                author: { avatar_url, login, html_url } = {},
                commit: { author: { date } = {} } = {},
            },
        } = this.state;

        const parseDate = +new Date(date);

        return (
            <div className={style.container}>
                <div className={style.container__header}>
                    <h1 className={style.container__header_title}>Docs and sources</h1>
                    <a
                        href={`${GITHUB_CONSTANTS}/edit/master/README.md`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={style.container__header_github}
                    >
                        <img src={githubIcon} alt="icon" />
                        <span>{t('Edit page')}</span>
                    </a>
                </div>
                <div className={style.lastEdit}>
                    <img src={avatar_url} className={style.lastEdit__img} alt="icon" />
                    <div className={style.lastEdit__wrapper}>
                        <span>Last edit:</span>
                        <a href={html_url} target="_blank" rel="noopener noreferrer">
                            @{login},
                        </a>
                        <span>
                            <Moment format="DD.MM.YYYY, hh:mm A" unix>
                                {parseDate / 1000}
                            </Moment>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withTranslation())(DocsPage);
