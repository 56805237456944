import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../../../utils';
import logo from '../../assets/images/logos/btcu.svg';
import style from './loading-page.module.scss';

const LoadingPage = ({ isLoader }) => {
    const containerStyle = isLoader
        ? style.loading
        : classNames(style.loading, style.loaded);

    return (
        <div className={containerStyle}>
            <img src={logo} alt="logo" />
            <div className={style.spinner}>
                <div className={style.dot1} />
                <div className={style.dot2} />
            </div>
        </div>
    );
};

LoadingPage.defaultProps = {
    isLoader: true,
};

LoadingPage.propTypes = {
    isLoader: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        loader: { isLoader },
    } = state;

    return {
        isLoader,
    };
};

export default compose(connect(mapStateToProps))(LoadingPage);
