import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import orionIcon from './orion-icon.svg';
import style from './orion-wallet.module.scss';

const OrionWallet = ({ title, path }) => {
    const { t } = useTranslation();

    return (
        <div className={style.container}>
            <div className={style.container__wrapper}>
                <h2 className={style.container__title}>{t('navigation.tryIt')}</h2>
                <div className={style.container__rightSide}>
                    <p className={style.container__rightSide_title}>
                        Orion <span>{title}</span>
                    </p>
                    <div className={style.container__content}>
                        <div className={style.achievements}>
                            <div className={style.achievements__item}>
                                <img
                                    className={style.achievements__item_img}
                                    src={orionIcon}
                                    alt="img"
                                />
                                <p className={style.achievements__item_text}>
                                    {t('texts.orionText')}
                                    <Link
                                        to={path}
                                        className={style.achievements__item_link}
                                    >
                                        {t('texts.readMore')}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

OrionWallet.defaultProps = {
    title: '',
    path: '',
};

OrionWallet.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
};


export default OrionWallet;
