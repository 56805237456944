const walletPath = '/wallet';
const storePath = '/store';
const explorerPath = '/explorer';
const whoWeArePath = '/who-we-are';
const howItWorksPath = '/how-does-it-work';
const developersIntroPath = '/developers-intro';
const developersPath = '/developers';
const developersDocsPath = '/developers/docs';
const binarisAndBuildsPath = '/developers/binaris-and-builds';
const orionPath = '/developers-orion';
const partnershipPath = '/partnership';
const communityPath = '/community';
const productsPath = '/products';
const smartContractsPath = '/smart-contracts';
const ultimatumPosPath = '/ultimatum-pos';
const airDropPath = '/air-drop';
const leasingAndStakingPath = '/leasing-and-staking';
const privateTransactionsPath = '/private-transactions';
const bugBountyPath = '/bug-bounty';
const termOfServicePath = '/term-of-service';
const privacyPolicyPath = '/privacy-policy';
export {
    walletPath,
    storePath,
    explorerPath,
    developersPath,
    developersDocsPath,
    developersIntroPath,
    whoWeArePath,
    howItWorksPath,
    binarisAndBuildsPath,
    orionPath,
    partnershipPath,
    communityPath,
    productsPath,
    smartContractsPath,
    ultimatumPosPath,
    leasingAndStakingPath,
    privateTransactionsPath,
    airDropPath,
    bugBountyPath,
    termOfServicePath,
    privacyPolicyPath,
};
