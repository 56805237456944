/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import background from './images/background.svg';
import listIcon from './images/list-icon.svg';
import mapIcon from './images/map.png';
import style from './about.module.scss';

const About = () => {
    const { t } = useTranslation();

    const listData = [
        {
            id: 1,
            text: t('texts.zerocoinProtocolText2'),
        },
        {
            id: 2,
            text: t('texts.zerocoinProtocolText3'),
        },
        {
            id: 3,
            text: t('texts.zerocoinProtocolText4'),
        },
        {
            id: 4,
            text: t('texts.zerocoinProtocolText5'),
        },
    ];

    const listBottomData = [
        {
            id: 1,
            text: t('texts.zerocoinProtocolText6'),
        },
        {
            id: 2,
            text: t('texts.zerocoinProtocolText7'),
        },
    ];

    return (
        <div className={style.about}>
            <div className={style.about__wrapper}>
                <h2 className={style.about__title}>{t('aboutUs.howItWorksText')}</h2>
                <div className={style.about__rightSide}>
                    <p
                        className={style.about__rightSide_title}
                        dangerouslySetInnerHTML={{
                            __html: t('headings.zerocoinProtocol'),
                        }}
                    />
                    <div className={style.about__content}>
                        <p
                            className={style.about__content_text}
                            dangerouslySetInnerHTML={{
                                __html: t('texts.zerocoinProtocolText1'),
                            }}
                        />
                        <div className={style.about__contentBg}>
                            {listData.map(item => {
                                const { id, text } = item;

                                return (
                                    <div key={id} className={style.about__list}>
                                        <img src={listIcon} alt="listIcon" />
                                        <p className={style.about__content_text}>
                                            {text}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <img src={mapIcon} alt="map" className={style.about__map} />
                        <div className={style.about__contentBg}>
                            {listBottomData.map(item => {
                                const { id, text } = item;

                                return (
                                    <div key={id} className={style.about__list}>
                                        <img src={listIcon} alt="listIcon" />
                                        <p className={style.about__content_text}>
                                            {text}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <img
                            src={background}
                            alt="background"
                            className={style.about__background}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
