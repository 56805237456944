import React from 'react';
import PropTypes from 'prop-types';

const LinkedIn = ({ className }) => (
    <svg
        className={className}
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.38385 7.16304V19.0506H0.131793V7.16304H5.38385ZM0.787808 4.54675C0.2626 4.0218 0 3.39525 0 2.66709C0 1.93893 0.266977 1.31238 0.800938 0.787426C1.3349 0.262473 1.98702 0 2.75733 0C3.52763 0 4.17976 0.262473 4.71372 0.787426C5.24768 1.31238 5.51466 1.93893 5.51466 2.66709C5.51466 3.39525 5.24768 4.0218 4.71372 4.54675C4.17976 5.0717 3.52763 5.33418 2.75733 5.33418C1.98702 5.33418 1.33052 5.0717 0.787808 4.54675ZM15.7037 19.0506V12.3702C15.7037 12.0315 15.5987 11.7521 15.3886 11.532C15.1785 11.3118 14.9071 11.2018 14.5745 11.2018C14.2419 11.2018 13.9705 11.3118 13.7604 11.532C13.5504 11.7521 13.4453 12.0315 13.4453 12.3702V19.0506H8.19326V7.16303H13.4453V8.48387C14.3382 7.4001 15.5461 6.85822 17.0692 6.85822C18.2072 6.85822 19.1394 7.24346 19.8659 8.01396C20.5925 8.78445 20.9558 9.83011 20.9558 11.151V19.0506H15.7037Z"
            fill="#ffffff"
        />
    </svg>
);

LinkedIn.defaultProps = {
    className: '',
};

LinkedIn.propTypes = {
    className: PropTypes.string,
};

export default LinkedIn;
